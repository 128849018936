@charset "UTF-8";
/* ============================================================ *\

  #MAIN

  Author:          Timotheus Triebl
  Website:         timotheus-triebl.at
  Scss Creator by: Andrew Brandwood www.Brandwood.com

/* ============================================================ */
/**
* CONTENTS
*
*/
@font-face {
  font-family: "HarmonySans";
  font-weight: 300;
  src: url("../Fonts/font-light.woff2") format("woff2"), url("../Fonts/font-light.woff") format("woff");
}
@font-face {
  font-family: "HarmonySans";
  font-weight: 400;
  src: url("../Fonts/font-regular.woff2") format("woff2"), url("../Fonts/font-regular.woff") format("woff");
}
@font-face {
  font-family: "HarmonySans";
  font-weight: 600;
  src: url("../Fonts/font-sb.woff2") format("woff2"), url("../Fonts/font-sb.woff") format("woff");
}
/*==================================
=            harmonysan            =
==================================*/
/*=============================
=            light            =
=============================*/
/*===============================
=            regular            =
===============================*/
/*================================
=            semibold            =
================================*/
/*============================================
=            bold/ maybe semibold            =
============================================*/
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}
*:focus {
  outline: none;
}

h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
dl, dd, ol, ul,
form, fieldset, legend,
table, th, td, caption,
hr {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

abbr[title], dfn[title] {
  cursor: help;
}

u, ins {
  text-decoration: none;
}

ins {
  border-bottom: 1px solid;
}

img {
  font-style: italic;
}

label,
input,
textarea,
button,
select,
option {
  cursor: pointer;
}

.input--text:active,
.input--text:focus,
textarea:active,
textarea:focus {
  cursor: text;
  outline: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

html {
  font-family: "Arial, sans-serif";
  font-size: 10px;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */
}

body {
  margin: 0;
  font-size: 10px;
}

a {
  color: black;
  text-decoration: none;
}

a:focus {
  outline: none;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

hr {
  box-sizing: content-box;
  height: 0;
}

mark {
  background: #ff0;
  color: #000;
}

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre-wrap;
}

q {
  quotes: "“" "”" "‘" "’";
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */
}

button,
input,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 2 */
  margin: 0;
  /* 3 */
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

input[type=search] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: normal;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 1em;
}

.image-wrapper {
  position: relative;
}
.image-wrapper .image {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-wrapper .image-contain {
  object-fit: contain;
}
.image-wrapper .image.image-relative, .image-wrapper .image.is-relative {
  position: relative;
  width: 100%;
  height: auto;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
}

button {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
}

html {
  transition: opacity 0.5s ease-in-out;
}
html.is_hidden_op {
  opacity: 0;
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #4c543e;
}
body a {
  color: #4c543e;
}
body.is-fixed {
  overflow: hidden;
}
.site {
  height: auto;
  width: 100%;
  position: relative;
  z-index: 1;
}
.site.is-active-acc {
  z-index: 999;
}
@media all and (min-width: 1023px) {
  .site.is-active-acc {
    z-index: 99;
  }
}

.section {
  display: block;
  width: 100%;
  position: relative;
}

/*==============================
=            button            =
==============================*/
button:not(.button-simple) {
  border: 1px solid #4c543e;
  text-transform: uppercase;
  width: auto;
  height: auto;
  position: relative;
  display: flex;
  transition: all 0.15s ease-in-out;
}
button:not(.button-simple) a {
  transition: all 0.15s ease-in-out;
  font-family: "HarmonySans", sans-serif;
  font-size: 1em;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: 2px;
  padding: 1.2em 2em 1.1em 2em;
}
@media all and (min-width: 1023px) {
  button:not(.button-simple) a {
    font-size: 1.2em;
  }
}
button:not(.button-simple).button_hover:hover, .button_hover:hover button:not(.button-simple) {
  border: 1px solid #ffffff;
}
button:not(.button-simple).button_hover:hover a, .button_hover:hover button:not(.button-simple) a {
  color: #ffffff;
  background-color: #4c543e;
}
button:not(.button-simple).no-a {
  font-family: "HarmonySans", sans-serif;
  font-size: 1em;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: 2px;
  padding: 1.2em 2em 1.1em 2em;
}
@media all and (min-width: 1023px) {
  button:not(.button-simple).no-a {
    font-size: 1.2em;
  }
}
button:not(.button-simple).is_turquoise {
  border: 1px solid #00a09b;
}
button:not(.button-simple).is_turquoise a {
  color: #ffffff;
  background-color: #00a09b;
}
button:not(.button-simple).is_turquoise.no-a {
  color: #ffffff;
  background-color: #00a09b;
}
button:not(.button-simple).is_turquoise.button_hover:hover, .button_hover:hover button:not(.button-simple).is_turquoise {
  border: 1px solid #00a09b;
}
button:not(.button-simple).is_turquoise.button_hover:hover a, .button_hover:hover button:not(.button-simple).is_turquoise a {
  color: #00a09b;
  background-color: #ffffff;
}
button:not(.button-simple).is_turquoise.button_hover:hover.no-a, .button_hover:hover button:not(.button-simple).is_turquoise.no-a {
  color: #00a09b;
  background-color: #ffffff;
}
button:not(.button-simple).is-white {
  border: 1px solid #ffffff;
}
button:not(.button-simple).is-white a {
  color: #ffffff;
  background-color: transparent;
}
button:not(.button-simple).is-white.button_hover:hover, .button_hover:hover button:not(.button-simple).is-white {
  border: 1px solid transparent;
}
button:not(.button-simple).is-white.button_hover:hover a, .button_hover:hover button:not(.button-simple).is-white a {
  color: #4c543e;
  background-color: #ffffff;
}
button:not(.button-simple).is-white-bg {
  border: 1px solid #ffffff;
}
button:not(.button-simple).is-white-bg a {
  color: #4c543e;
  background-color: #ffffff;
}
button:not(.button-simple).is-white-bg.button_hover:hover, .button_hover:hover button:not(.button-simple).is-white-bg {
  border: 1px solid #4c543e;
}
button:not(.button-simple).is-white-bg.button_hover:hover a, .button_hover:hover button:not(.button-simple).is-white-bg a {
  color: #ffffff;
  background-color: #4c543e;
}

/*=========================================
=            container witdths            =
=========================================*/
.section {
  display: flex;
}
.section .inner-wrapper {
  width: 84%;
  margin: 0 auto;
}
.section.width-small .inner-wrapper {
  width: 84%;
  max-width: 500px;
}
@media all and (min-width: 1023px) {
  .section.width-small .inner-wrapper {
    width: 59%;
    max-width: 760px;
  }
}

.intro-insights .inner-wrapper {
  width: 84%;
  margin: 0 auto;
}

.insights-overview .insights-inner {
  width: 84%;
  margin: 0 auto;
}

nav .nav-head {
  width: 84%;
  margin: 0 auto;
}

@media all and (min-width: 1023px) {
  .section-InsightsTeaser .inner-wrapper {
    width: 92%;
    margin: 0 0 0 auto;
  }
}

@media all and (min-width: 1023px) {
  .section-ImgText.is_horizontal .inner-wrapper {
    width: 92%;
    margin: 0 auto 0 0;
  }
  .section-ImgText.is_horizontal .inner-wrapper .module-headline {
    margin-left: 8.6%;
  }
}
@media all and (min-width: 1023px) {
  .section-ImgText.is_horizontal.is_mirrored .inner-wrapper {
    width: 92%;
    margin: 0 0 0 auto;
  }
}
.site-footer .inner-wrapper {
  width: 100%;
  padding-left: 8%;
  padding-right: 8%;
  margin: 0 auto;
}

@media all and (min-width: 1023px) {
  .section-HeadlineImg .inner-wrapper .image-wrapper {
    margin-left: 15%;
  }
}

@media all and (min-width: 1023px) {
  .section-Accordion .inner-wrapper {
    width: 100%;
  }
}
@media all and (min-width: 1023px) {
  .section-Accordion .inner-wrapper .accordion .accordion-item {
    padding: 0 8%;
  }
}
.section-Accordion .inner-wrapper .accordion .accordion-item .content {
  width: 84%;
}
@media all and (min-width: 1023px) {
  .section-Accordion .inner-wrapper .accordion .accordion-item .content {
    width: 70% !important;
  }
}

.section-ExpertsSlider .inner-wrapper {
  width: 92%;
  margin: 0 0 0 auto;
}

.section-Info .inner-wrapper {
  width: 92%;
  margin: 0 auto 0 0;
}
.section-Info .inner-wrapper .column-left,
.section-Info .inner-wrapper .headline {
  padding: 0 0 0 8%;
}
.section-Info .inner-wrapper .column-right {
  padding-left: 8%;
}
@media all and (min-width: 1023px) {
  .section-Info .inner-wrapper .column-right {
    padding-left: 0;
  }
}
@media all and (min-width: 1023px) {
  .section-Info .inner-wrapper .column-left {
    padding: 0;
  }
}
@media all and (min-width: 1023px) {
  .section-Info .inner-wrapper .column-left .info-item-1 .title,
.section-Info .inner-wrapper .column-left .info-item-1 .text {
    padding-left: 18.4%;
  }
}
@media all and (min-width: 1023px) {
  .section-Info .inner-wrapper .column-left .info-item-1 button {
    margin-left: 18.4%;
  }
}
@media all and (min-width: 1023px) {
  .section-Info .inner-wrapper .column-left .info-item-2 {
    padding-left: 18.4%;
  }
}

.section-Imagequote {
  width: 100%;
}
.section-Imagequote .inner-wrapper {
  width: 100%;
}

/*==========================================
=            module margins top            =
==========================================*/
.section-ImgText {
  padding-top: 25px;
}
@media all and (min-width: 1023px) {
  .section-ImgText {
    padding-top: 77px;
  }
}
.section-ImgText + .section-ImgText {
  padding-top: 60px;
}
@media all and (min-width: 1023px) {
  .section-ImgText + .section-ImgText {
    padding-top: 80px;
  }
}

.section-Quote {
  padding-top: 96px;
}
@media all and (min-width: 1023px) {
  .section-Quote {
    padding-top: 190px;
    padding-bottom: 5em;
  }
}

.single-experts .section-Quote {
  padding-top: 53px;
  padding-bottom: 53px;
  margin-top: 0;
}
@media all and (min-width: 1023px) {
  .single-experts .section-Quote {
    margin-top: 0;
    padding-top: 132px;
    padding-bottom: 143px;
  }
}

.site-footer .inner-wrapper {
  padding-top: 29px;
}
@media all and (min-width: 1023px) {
  .site-footer .inner-wrapper {
    padding-top: 80px;
  }
}

.section-intro + .section-Text {
  margin-top: 32px;
}
@media all and (min-width: 1023px) {
  .section-intro + .section-Text {
    padding-top: 35px;
    margin-top: 70px;
  }
}

.section-Text {
  padding-top: 22px;
}
@media all and (min-width: 1023px) {
  .section-Text {
    padding-top: 100px;
    padding-bottom: 3em;
    margin-top: 0;
  }
}

.section-HeadlineImg {
  margin-top: 17px;
}
@media all and (min-width: 1023px) {
  .section-HeadlineImg {
    padding-top: 100px;
    margin-top: 0;
  }
}

.section-Accordion {
  margin-top: 38px;
  padding-top: 29px;
}
.section-Accordion.no_headline {
  padding-top: 0;
}
@media all and (min-width: 1023px) {
  .section-Accordion {
    padding-top: 110px;
    margin-top: 70px;
  }
}

.section-FourLogos {
  margin-top: 29px;
}
@media all and (min-width: 1023px) {
  .section-FourLogos {
    padding-top: 30px;
    margin-top: 80px;
  }
}
.section-VideoImg {
  margin-top: 60px;
}
@media all and (min-width: 1023px) {
  .section-VideoImg {
    margin-top: 0;
    padding-top: 101px;
  }
}
.section-ExpertsSlider {
  padding-top: 30px;
  margin-top: 89px;
  padding-bottom: 46px;
  margin-bottom: 30px;
}
@media all and (min-width: 1023px) {
  .section-ExpertsSlider {
    padding-top: 110px;
    padding-bottom: 98px;
    margin-top: 70px;
    margin-bottom: 7em;
  }
}
.section-InsightsTeaserTwo {
  padding-top: 50px;
  padding-bottom: 123px;
}
@media all and (min-width: 1023px) {
  .section-InsightsTeaserTwo {
    padding-top: 89px;
    padding-bottom: 88px;
  }
}
.section-Numbers {
  padding-top: 25px;
}
@media all and (min-width: 1023px) {
  .section-Numbers {
    padding-top: 77px;
  }
}
.section-Ranking {
  padding-top: 49px;
  padding-bottom: 49px;
}
@media all and (min-width: 1023px) {
  .section-Ranking {
    padding-top: 120px;
    padding-bottom: 100px;
  }
}
.section-TextLarge {
  padding-top: 28px;
}
@media all and (min-width: 1023px) {
  .section-TextLarge {
    padding-top: 90px;
  }
}
.section-Gallery {
  padding-top: 63px;
}
@media all and (min-width: 1023px) {
  .section-Gallery {
    padding-top: 185px;
  }
}
.section-Info {
  padding-top: 50px;
  padding-bottom: 57px;
}
@media all and (min-width: 1023px) {
  .section-Info {
    padding-top: 120px;
    padding-bottom: 250px;
  }
}
.section-TeaserBgImageThree + .section-ExpertsSlider {
  margin-top: 0;
}

.section-ImgText + .section-TeaserBgImageThree {
  margin-top: 96px;
}
@media all and (min-width: 1023px) {
  .section-ImgText + .section-TeaserBgImageThree {
    margin-top: 150px;
  }
}

.single-insights .site-footer {
  padding-top: 0;
}

.section-Text + .section-Accordion {
  margin-top: 0;
}

.section-Imagequote + .section-ExpertsSlider {
  margin-top: 0;
}

.section-TextLarge + .section-Text {
  padding-top: 10px;
}
@media all and (min-width: 1023px) {
  .section-TextLarge + .section-Text {
    padding-top: 30px;
  }
}

.section-TextLarge + .section-Accordion {
  margin-top: 0;
}

.section-TeaserBgImageTwo + .section-ExpertsSlider {
  margin-top: 0;
}

.section-VideoImg + .section-Info {
  margin-top: 100px;
}
@media all and (min-width: 1023px) {
  .section-VideoImg + .section-Info {
    margin-top: 0;
  }
}

/*===================================
=            style Lines            =
===================================*/
.lines {
  width: 100%;
  height: 100%;
  padding: 0 4%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  display: flex;
  justify-content: space-between;
}
.lines .line {
  width: 2px;
  height: 100%;
  left: 0;
  top: 0;
  position: relative;
  background-color: #00a09b;
}
.lines .line-right {
  right: 0;
  left: auto;
}

.section-InsightsTeaser .lines {
  display: none;
  top: 70px;
}
@media all and (min-width: 1023px) {
  .section-InsightsTeaser .lines {
    display: flex;
  }
}

.intro-contact .lines,
.intro-insights .lines,
.intro-1 .lines,
.intro-2 .lines,
.intro-3 .lines,
.intro-4 .lines {
  z-index: 1;
  top: 90px;
}
@media all and (min-width: 1023px) {
  .intro-contact .lines,
.intro-insights .lines,
.intro-1 .lines,
.intro-2 .lines,
.intro-3 .lines,
.intro-4 .lines {
    top: 170px;
  }
}
.intro-contact .lines .line,
.intro-insights .lines .line,
.intro-1 .lines .line,
.intro-2 .lines .line,
.intro-3 .lines .line,
.intro-4 .lines .line {
  background-color: #ffffff;
}

.intro-1 .lines .line {
  background-color: #00a09b;
}

.section-TeaserBgImage .lines {
  display: none;
}
@media all and (min-width: 1023px) {
  .section-TeaserBgImage .lines {
    display: flex;
  }
}

.home .section-ImgText .lines {
  height: 97px;
  display: none;
}
@media all and (min-width: 1023px) {
  .home .section-ImgText .lines {
    display: flex;
  }
}
.home .section-ImgText + .section-ImgText .lines {
  display: none;
}

@media all and (min-width: 1023px) {
  .section-ImgText + .section-Quote .lines {
    top: 70px;
  }
}

.section-ImgText .lines {
  display: none;
}
@media all and (min-width: 1023px) {
  .section-ImgText .lines {
    display: flex;
    height: calc(100% + 80px);
  }
}

.section-Gallery .lines {
  display: none;
}
@media all and (min-width: 1023px) {
  .section-Gallery .lines {
    display: flex;
  }
}

.section-Info .lines {
  display: none;
}
@media all and (min-width: 1023px) {
  .section-Info .lines {
    display: flex;
  }
}

.section-Quote .lines {
  display: none;
}
@media all and (min-width: 1023px) {
  .section-Quote .lines {
    display: flex;
  }
}

.section-TeaserBgImageThree .lines {
  display: none;
}

.section-Ranking {
  overflow: hidden;
}
.section-Ranking .lines {
  display: none;
}
@media all and (min-width: 1023px) {
  .section-Ranking .lines {
    display: flex;
    top: 70px;
  }
}

.section-FourLogos .lines {
  display: none;
}
@media all and (min-width: 1023px) {
  .section-FourLogos .lines {
    display: flex;
  }
}

.team-content {
  overflow: hidden;
}
.team-content .lines {
  top: 70px;
  display: none;
}
@media all and (min-width: 1023px) {
  .team-content .lines {
    display: flex;
  }
}

.section-VideoImg .lines {
  display: none;
}
@media all and (min-width: 1023px) {
  .section-VideoImg .lines {
    display: flex;
  }
}

.section-HeadlineImg .lines {
  display: none;
}
@media all and (min-width: 1023px) {
  .section-HeadlineImg .lines {
    display: flex;
  }
}

.section-InsightsTeaserTwo {
  overflow: hidden;
}
.section-InsightsTeaserTwo .lines {
  top: 30px;
}
@media all and (min-width: 1023px) {
  .section-InsightsTeaserTwo .lines {
    top: 60px;
  }
}

.section-Numbers + .section-Text {
  padding-bottom: 0;
}
@media all and (min-width: 1023px) {
  .section-Numbers + .section-Text .lines {
    top: 60px;
  }
}

.section-Text:first-child .lines {
  top: 70px;
}
.section-Text .lines {
  display: none;
}
@media all and (min-width: 1023px) {
  .section-Text .lines {
    display: flex;
  }
}

.page.page-id-316 .site-footer .lines {
  display: none;
}

.page-template-team .site-footer .lines {
  display: flex;
}
@media all and (min-width: 1023px) {
  .page-template-team .site-footer .lines {
    display: none;
  }
}

.page-template-team .site-footer .lines {
  height: 90px;
}
@media all and (min-width: 1023px) {
  .page-template-team .site-footer .lines {
    display: flex;
  }
}

.insights-overview .lines {
  top: auto;
  bottom: 0;
  height: 70px;
}

.section-Info .lines {
  height: 180px;
}

.site-footer .lines {
  height: 90px;
}
@media all and (min-width: 1023px) {
  .site-footer .lines {
    display: flex;
  }
}
.site-footer.has-space .lines {
  height: 345px;
  top: -145px;
}
@media all and (min-width: 1023px) {
  .site-footer.has-space .lines {
    height: 345px;
    top: -145px;
    display: flex;
  }
}

/*==================================
=            open close            =
==================================*/
.svg-icn-open-to-close {
  width: 19px;
  height: 19px;
  transition: all 0.15s ease-in-out;
  padding: 7px;
  box-sizing: content-box;
}
@media all and (min-width: 1023px) {
  .svg-icn-open-to-close {
    width: 28px;
    height: 28px;
  }
}
.svg-icn-open-to-close path {
  transition: all 0.15s ease-in-out;
}

.is-active .svg-icn-open-to-close {
  border-radius: 100%;
  background-color: #00a09b;
  transform: rotate(45deg);
}
.is-active .svg-icn-open-to-close path {
  stroke: #ffffff;
}

/*===============================
=            buttons            =
===============================*/
button.button-simple {
  font-family: "HarmonySans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: 3px;
  color: #00a09b;
  border: 0;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
button.button-simple:before {
  content: "";
  width: 40px;
  display: block;
  height: 1px;
  background-color: #00a09b;
  margin-right: 6px;
  transition: width 0.15s ease-in-out;
}
.button_hover:hover button.button-simple:before {
  width: 20px;
}

/*===================================
=            nice select            =
===================================*/
.nice-select {
  border-radius: 0;
  border: solid 1px #4c543e;
  min-width: 280px;
  width: 100%;
  white-space: normal;
  margin-top: 10px;
}
@media all and (min-width: 1023px) {
  .nice-select {
    margin: 0 5px;
    width: auto;
  }
}
.nice-select:first-child {
  margin-top: 0;
}
.nice-select .current {
  text-transform: uppercase;
  font-family: "HarmonySans", sans-serif;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 1.67em;
  letter-spacing: 2px;
  white-space: nowrap;
  display: inline-flex;
  max-width: 100%;
  overflow: hidden;
  margin-top: 0.5em;
}
.nice-select .list {
  border-radius: 0;
  border: solid 1px #4c543e;
  min-width: calc(100% + 2px);
  margin-top: 0;
  left: -1px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media all and (min-width: 1023px) {
  .nice-select .list {
    width: auto;
  }
}
.nice-select .list .option {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 1.25em;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #4c543e;
  padding: 0.5em 29px 0.5em 18px;
  margin-top: 0.1em;
}
.nice-select .list .option:last-child {
  border-bottom: 0;
}
.nice-select .list .option:hover {
  background-color: #f3f3f2;
}
.nice-select:after {
  border-bottom: 1px solid #29293A;
  border-right: 1px solid #29293A;
  width: 6px;
  height: 6px;
}
.nice-select:before {
  content: "";
  background-color: #ffffff;
  width: 12px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

/*========================================
=            insight overview            =
========================================*/
.insights {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
@media all and (min-width: 1023px) {
  .is-two .insights {
    justify-content: flex-start;
  }
}
@media all and (min-width: 1023px) {
  .is-two .insights .insight-item:nth-child(2) {
    margin-left: 4%;
  }
}
@media all and (min-width: 1023px) {
  .insights {
    margin-top: 61px;
    flex-direction: row;
    justify-content: space-between;
  }
}
.insights .insight-item {
  width: 100%;
  margin-top: 53px;
}
.insights .insight-item:first-child {
  margin-top: 0;
}
@media all and (min-width: 1023px) {
  .insights .insight-item {
    width: 31%;
    margin-top: 0;
  }
  .insights .insight-item:first-child {
    margin-top: 0;
  }
}
@media all and (min-width: 1023px) {
  .insights .insight-item .image-wrapper {
    height: 0;
    padding-bottom: 120%;
  }
}
.insights .insight-item .image-wrapper img {
  position: relative;
  width: 100%;
  height: auto;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
}
@media all and (min-width: 1023px) {
  .insights .insight-item .image-wrapper img {
    position: absolute;
    object-fit: contain;
    bottom: 0;
  }
}
.insights .insight-item .image-wrapper .svg {
  position: absolute;
  width: 70px;
  height: auto;
  z-index: 1;
  top: 0;
  left: 0;
  padding-top: 2em;
  padding-left: 1.5em;
  box-sizing: content-box;
}
@media all and (min-width: 1023px) and (max-width: 1400px) {
  .insights .insight-item .image-wrapper .svg {
    width: 50px;
  }
}
.insights .insight-item .title {
  font-family: "HarmonySans", sans-serif;
  font-size: 2.5em;
  font-weight: 400;
  line-height: 1.2em;
  letter-spacing: normal;
  margin-top: 26px;
}
@media all and (min-width: 1023px) {
  .insights .insight-item .title {
    font-size: 3em;
    line-height: 1.33em;
  }
}
.insights .insight-item .info-wrapper {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 2.08em;
  letter-spacing: normal;
  margin-top: 7px;
}
.insights .insight-item .info-wrapper .author {
  color: rgba(76, 84, 62, 0.5);
}
.insights .insight-item .text {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
  margin-top: 15px;
}
@media all and (min-width: 1023px) {
  .insights .insight-item .text {
    font-size: 1.8em;
    line-height: 1.39em;
  }
}
.insights .insight-item .button {
  margin-top: 21px;
}

/*================================
=            go sepia            =
================================*/
.go_sepia:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #4c543e;
  opacity: 0.8;
  z-index: 1;
  pointer-events: none;
}
.go_sepia img {
  filter: grayscale(100%);
}

/*====================================
=            prevent link            =
====================================*/
.js_prevent_link {
  pointer-events: none;
}

/*==============================
=            loader            =
==============================*/
.svg-circle.initLoader {
  width: 70px;
  height: 70px;
  position: absolute;
  transform: rotate(45deg);
  backface-visibility: hidden;
  pointer-events: none;
  display: none;
  /*opacity: 0;*/
  /*animation: getSmaller 0.5s infinite 1s both;*/
  animation-name: loader;
  animation-duration: 2.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  /*animation-delay: 2s;*/
  pointer-events: none;
}
.svg-circle.initLoader circle {
  fill: transparent;
}
.svg-circle.initLoader.is-visible {
  display: block;
}

/*==============================
=            spacer footer           =
==============================*/
.single-kompetenzen .section-ExpertsSlider {
  margin-bottom: 0;
  padding-bottom: 30px;
}
@media all and (min-width: 1023px) {
  .single-kompetenzen .section-ExpertsSlider {
    padding-bottom: 150px;
  }
}

.spacer.spacer-footer {
  height: 90px;
  width: 100%;
}
.spacer.spacer-footer .lines {
  height: 100%;
}

/*==============================
=            cookie            =
==============================*/
.cookiemsg {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  display: flex;
  background-color: #ffffff;
  padding: 1.5em 4%;
  z-index: 1;
  transform: translate(0, 100%);
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.cookiemsg.is_visible {
  opacity: 1;
  transform: translate(0, 0);
}
.cookiemsg .inner-wrapper {
  width: auto;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.cookiemsg .inner-wrapper .text {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.43em;
  letter-spacing: normal;
  margin-right: 2em;
}
.cookiemsg .inner-wrapper a {
  opacity: 0.6;
}
.cookiemsg .inner-wrapper .button {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.43em;
  letter-spacing: normal;
}
/*=================================
=            sonstiges            =
=================================*/
.material-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.material-wrapper .material-icons {
  margin-left: -4px;
}
.material-wrapper .material-name {
  margin-top: 0.4em;
  margin-left: 0.2em;
}

.team-detail-intro {
  position: relative;
}
.team-detail-intro .image-wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 79%;
}
.team-detail-intro .image-wrapper:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: black;
  background: linear-gradient(9deg, rgba(0, 0, 0, 0.4) 0%, transparent 100%);
  z-index: 1;
}
@media all and (min-width: 1023px) {
  .team-detail-intro .image-wrapper {
    padding-bottom: 42%;
  }
}
.team-detail-intro .text-wrapper {
  position: absolute;
  bottom: 36px;
  left: 8%;
  color: #ffffff;
  z-index: 1;
  width: 84%;
}
@media all and (min-width: 1023px) {
  .team-detail-intro .text-wrapper {
    bottom: 97px;
  }
}
.team-detail-intro .text-wrapper .name {
  font-family: "HarmonySans", sans-serif;
  font-size: 4.5em;
  font-weight: 400;
  line-height: 1.11em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .team-detail-intro .text-wrapper .name {
    font-size: 6em;
    line-height: 1em;
  }
}
@media all and (max-width: 500px) {
  .team-detail-intro .text-wrapper .name {
    font-size: 3.7em;
  }
}
.team-detail-intro .text-wrapper .pos {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 2.5em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .team-detail-intro .text-wrapper .pos {
    font-size: 2.8em;
    line-height: 1.45em;
  }
}
@media all and (min-width: 1023px) {
  .team-detail-intro .text-wrapper .pos {
    margin-top: 10px;
  }
}

.team-detail-infos {
  background-color: #4c543e;
  padding-top: 22px;
  padding-bottom: 52px;
}
@media all and (min-width: 1023px) {
  .team-detail-infos {
    padding-top: 70px;
    padding-bottom: 73px;
  }
}
.team-detail-infos .detail-info-inner {
  width: 84%;
  margin: 0 auto;
}
@media all and (min-width: 1023px) {
  .team-detail-infos .detail-info-inner {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
}
.team-detail-infos .detail-info-inner .info-item {
  color: #ffffff;
  margin-top: 45px;
  max-width: 440px;
}
@media all and (min-width: 1023px) {
  .team-detail-infos .detail-info-inner .info-item {
    margin-top: 0;
  }
}
.team-detail-infos .detail-info-inner .info-item-about {
  margin-top: 0;
}
@media all and (min-width: 1023px) {
  .team-detail-infos .detail-info-inner .info-item-about {
    margin-right: auto;
    padding: 0 2em 0 0;
  }
}
.team-detail-infos .detail-info-inner .info-item-about .info-text {
  padding-top: 0;
}
@media all and (min-width: 1023px) {
  .team-detail-infos .detail-info-inner .info-item-about .info-text {
    padding-top: 17px;
  }
}
@media all and (min-width: 1023px) {
  .team-detail-infos .detail-info-inner .info-item-fachgebiete {
    margin-right: auto;
    padding: 0 2em;
  }
}
.team-detail-infos .detail-info-inner .info-item-fachgebiete .info-text {
  padding-top: 3px;
}
.team-detail-infos .detail-info-inner .info-item-fachgebiete .info-text ul li a {
  position: relative;
  display: flex;
}
.team-detail-infos .detail-info-inner .info-item-fachgebiete .info-text ul li a:before {
  content: "•";
  display: block;
  margin-right: 8px;
}
@media all and (min-width: 1023px) {
  .team-detail-infos .detail-info-inner .info-item-fachgebiete .info-text {
    padding-top: 17px;
  }
}
@media all and (min-width: 1023px) {
  .team-detail-infos .detail-info-inner .info-item-getintouch {
    margin-left: auto;
    padding: 0 2em;
  }
}
.team-detail-infos .detail-info-inner .info-item-getintouch .info-text {
  padding-top: 3px;
  display: flex;
}
@media all and (min-width: 1023px) {
  .team-detail-infos .detail-info-inner .info-item-getintouch .info-text {
    padding-top: 10px;
  }
}
.team-detail-infos .detail-info-inner .info-item-getintouch .info-text a {
  padding: 10px;
}
.team-detail-infos .detail-info-inner .info-item-getintouch .info-text a.linkedin {
  padding: 10px 10px 10px 0;
}
.team-detail-infos .detail-info-inner .info-item-backoffice {
  margin-top: 35px;
}
@media all and (min-width: 1023px) {
  .team-detail-infos .detail-info-inner .info-item-backoffice {
    margin-left: auto;
    margin-top: 0;
    padding: 0 0 0 2em;
  }
}
.team-detail-infos .detail-info-inner .info-item-backoffice .info-text {
  padding-top: 5px;
}
@media all and (min-width: 1023px) {
  .team-detail-infos .detail-info-inner .info-item-backoffice .info-text {
    padding-top: 17px;
  }
}
.team-detail-infos .detail-info-inner .info-item-backoffice .backoffice {
  text-decoration: underline;
}
.team-detail-infos .detail-info-inner .info-item .info-title {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 600;
  line-height: 1.56em;
  letter-spacing: normal;
  font-weight: 400;
}
@media all and (min-width: 1023px) {
  .team-detail-infos .detail-info-inner .info-item .info-title {
    font-size: 2em;
  }
}
.team-detail-infos .detail-info-inner .info-item .info-text {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .team-detail-infos .detail-info-inner .info-item .info-text {
    font-size: 2em;
    line-height: 1.5em;
  }
}
.team-detail-infos .detail-info-inner .info-item .info-text a {
  color: inherit;
}

.team-detail-highlights {
  padding-top: 50px;
  height: auto;
  position: relative;
  display: flex;
}
@media all and (min-width: 1023px) {
  .team-detail-highlights {
    padding-top: 115px;
  }
}
.team-detail-highlights .detail-highlights-inner {
  width: 84%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media all and (min-width: 1023px) {
  .team-detail-highlights .detail-highlights-inner {
    display: flex;
    margin: 0 auto 0 8%;
  }
}
.team-detail-highlights .detail-highlights-inner .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 3em;
  font-weight: 400;
  line-height: 1.33em;
  letter-spacing: normal;
  cursor: default;
}
@media all and (min-width: 1023px) {
  .team-detail-highlights .detail-highlights-inner .headline {
    font-size: 4.5em;
    line-height: 1.11em;
  }
}
@media all and (min-width: 1023px) {
  .team-detail-highlights .detail-highlights-inner .headline {
    letter-spacing: -1px;
    width: 40%;
    float: left;
  }
}
@media all and (min-width: 1023px) {
  .team-detail-highlights .detail-highlights-inner {
    float: left;
  }
}
.team-detail-highlights .detail-highlights-inner .highlight-item:first-child {
  margin-top: 13px;
}
@media all and (min-width: 1023px) {
  .team-detail-highlights .detail-highlights-inner .highlight-item:first-child {
    margin-top: 40px;
  }
}
.team-detail-highlights .detail-highlights-inner .highlight-item {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
  position: relative;
  margin-top: 30px;
  display: block;
  transition: color 0.25s ease-in-out;
  cursor: default;
}
@media all and (min-width: 1023px) {
  .team-detail-highlights .detail-highlights-inner .highlight-item {
    font-size: 2em;
    line-height: 1.5em;
  }
}
.team-detail-highlights .detail-highlights-inner .highlight-item.has_before {
  padding-left: 15px;
}
.team-detail-highlights .detail-highlights-inner .highlight-item.has_before:before {
  content: "•";
  display: block;
  margin-right: 8px;
  position: absolute;
  left: 0;
}
.team-detail-highlights .detail-highlights-inner .highlight-item .linktext {
  margin-left: 0.2em;
}
.team-detail-highlights .detail-highlights-inner .show-hide-wrapper {
  overflow: hidden;
  width: 100%;
  max-height: 0;
  transition: max-height 0.25s ease-in-out;
}
.team-detail-highlights .detail-highlights-inner button {
  margin-top: 50px;
}
.team-detail-highlights .detail-highlights-inner button.button-simple {
  margin-top: 0.7em;
}
.team-detail-highlights-new {
  transition: padding-bottom 0.25s ease-in-out;
}
.team-detail-highlights-new.has_pb {
  padding-bottom: 91px;
}
.team-detail-highlights-new .detail-highlights-inner .highlight-item {
  cursor: pointer;
}
.team-detail-highlights-new .detail-highlights-inner .highlight-item:hover {
  color: #00a09b;
}
.team-detail-highlights-new .detail-highlights-inner .highlight-item:first-child {
  margin-top: 30px;
}
@media all and (min-width: 1023px) {
  .team-detail-highlights-new .detail-highlights-inner .highlight-item:first-child {
    margin-top: 30px;
  }
}
.team-detail-highlights-new button.button-togglePub {
  transition: all 0.25s ease-in-out 0.2s, opacity 0.25s ease-in-out;
  position: absolute;
  bottom: 0;
  margin-top: 0;
}
.team-detail-highlights-new button.button-togglePub.is-hidden {
  opacity: 0;
  pointer-events: none;
}

.single-experts .team-detail-highlights:last-child {
  padding-bottom: 5em;
}
@media all and (min-width: 1023px) {
  .single-experts .team-detail-highlights:last-child {
    padding-bottom: 13em;
  }
}

.single-experts .team-detail-highlights + .section-InsightsTeaserTwo {
  margin-top: 5em;
}
@media all and (min-width: 1023px) {
  .single-experts .team-detail-highlights + .section-InsightsTeaserTwo {
    margin-top: 13em;
  }
}

.single-experts .section-Quote + .team-detail-highlights {
  padding-top: 0;
}
/*================================
=            overview            =
================================*/
.filters-team {
  position: relative;
  top: 0;
  width: 100%;
  padding: 0 8%;
  margin-top: 30px;
}
@media all and (min-width: 1023px) {
  .filters-team {
    margin-top: 0;
    height: auto;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    top: 7em;
    z-index: 1;
  }
}

.team-content {
  padding-bottom: 44px;
}
@media all and (min-width: 1023px) {
  .team-content {
    padding-bottom: 44px;
  }
}
.team-content .inner-wrapper {
  margin-top: -3em;
}
@media all and (min-width: 1023px) {
  .team-content .inner-wrapper {
    margin-top: -8.5em;
  }
}
.team-content .inner-wrapper .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 2.5em;
  font-weight: 400;
  line-height: 2em;
  letter-spacing: normal;
  line-height: 2.4em;
  margin-top: 47px;
  margin-bottom: -0.7em;
}
@media all and (min-width: 1023px) {
  .team-content .inner-wrapper .headline {
    font-size: 4.5em;
    line-height: 1.33em;
  }
}
.team-content .inner-wrapper .headline.is-hidden {
  display: none;
}
@media all and (min-width: 1023px) {
  .team-content .inner-wrapper .headline {
    margin-top: 146px;
    margin-bottom: 0;
  }
}
.team-content .inner-wrapper .team-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.team-content .inner-wrapper .team-wrapper.is-hidden {
  display: none;
}
@media all and (min-width: 1023px) {
  .team-content .inner-wrapper .team-wrapper {
    margin: 40px -20px 0 -20px;
  }
}
.team-content .inner-wrapper .team-wrapper .team-item {
  width: 100%;
  margin-top: 24px;
}
.team-content .inner-wrapper .team-wrapper .team-item.is-hidden {
  display: none;
}
@media all and (min-width: 1023px) {
  .team-content .inner-wrapper .team-wrapper .team-item {
    padding: 0 20px;
    width: 33%;
    margin-top: 50px;
  }
  .team-content .inner-wrapper .team-wrapper .team-item:first-child {
    margin-top: 50px;
  }
}
.team-content .inner-wrapper .team-wrapper .team-item .title {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.88em;
  letter-spacing: normal;
  margin-top: 9px;
}
@media all and (min-width: 1023px) {
  .team-content .inner-wrapper .team-wrapper .team-item .title {
    margin-top: 16px;
    font-size: 2em;
    line-height: 1.5em;
  }
}

.single-insights .intro-insights {
  height: auto;
  background-color: #4c543e;
  padding-top: 100px;
  padding-bottom: 100px;
}
@media all and (min-width: 1023px) {
  .single-insights .intro-insights {
    padding-top: 234px;
    padding-bottom: 182px;
  }
}
.single-insights .intro-insights .text-wrapper {
  max-width: unset;
  padding-left: 8%;
  padding-right: 8%;
  position: relative;
  top: 0;
  transform: unset;
  max-width: 860px;
}
.single-insights .intro-insights .text-wrapper .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 3em;
  font-weight: 400;
  line-height: 1.33em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .single-insights .intro-insights .text-wrapper .headline {
    font-size: 6em;
    line-height: 1em;
  }
}
@media all and (min-width: 1023px) {
  .single-insights .intro-insights .text-wrapper .headline {
    line-height: 1.17em;
  }
}
.single-insights .intro-insights .text-wrapper .subtext .info-wrapper {
  font-family: "HarmonySans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.79em;
  letter-spacing: normal;
}
.single-insights .intro-insights .text-wrapper .subtext .info-wrapper .author {
  color: rgba(250, 250, 250, 0.4);
}

.image-after-intro-insights {
  width: 84%;
  margin: 0 auto;
  margin-top: -77px;
}
.image-after-intro-insights .svg {
  position: absolute;
  width: 70px;
  height: auto;
  z-index: 1;
  top: 0;
  left: 0;
  padding-top: 2em;
  padding-left: 1.5em;
  box-sizing: content-box;
}
@media all and (min-width: 1023px) and (max-width: 1400px) {
  .image-after-intro-insights .svg {
    width: 50px;
  }
}
@media all and (min-width: 1023px) {
  .image-after-intro-insights {
    width: 59%;
    max-width: 760px;
  }
}

@media all and (min-width: 1023px) {
  .insight-detail-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.insight-detail-content .icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 84%;
  margin: 22px auto 0 auto;
}
@media all and (min-width: 1023px) {
  .insight-detail-content .icons {
    flex-direction: column;
    align-items: flex-start;
    position: sticky;
    width: auto;
    left: 8%;
    top: 193px;
    margin: 193px 0 120px 0;
    width: 92%;
  }
}
.insight-detail-content .icons .icon-link {
  padding: 0.6em 1.3em;
}
.insight-detail-content .icons .icon-link:first-child {
  padding: 0.6em 1.3em 0.6em 0;
}
@media all and (min-width: 1023px) {
  .insight-detail-content .icons .icon-link {
    padding: 0.6em;
  }
  .insight-detail-content .icons .icon-link:first-child {
    padding: 0.6em;
  }
  .insight-detail-content .icons .icon-link.icon-link-email {
    padding: 0.6em 0.6em 0.6em 0.7em;
  }
}
.insight-detail-content .content-inner {
  width: 84%;
  margin: 0 auto;
  padding-bottom: 50px;
  margin-top: 8px;
}
@media all and (min-width: 1023px) {
  .insight-detail-content .content-inner {
    width: 59%;
    padding-bottom: 100px;
    max-width: 760px;
    margin-top: -331px;
  }
}
.insight-detail-content .content-inner .teaser {
  padding-top: 0;
  font-family: "HarmonySans", sans-serif;
  font-size: 2em;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .insight-detail-content .content-inner .teaser {
    padding-top: 0;
    margin-top: 0;
  }
}
@media all and (min-width: 1023px) {
  .insight-detail-content .content-inner .teaser {
    font-size: 3em;
    line-height: 1.33em;
  }
}
.insight-detail-content .content-inner .text {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .insight-detail-content .content-inner .text {
    font-size: 2em;
    line-height: 1.5em;
  }
}
.insight-detail-content .content-inner .text p {
  padding: 23px 0 0 0;
}
.insight-detail-content .content-inner .text a {
  opacity: 0.6;
}
.insight-detail-content .content-inner .text ul {
  padding: 23px 0 0 0;
}
.insight-detail-content .content-inner .text ul li {
  display: block;
  padding-left: 1em;
  position: relative;
}
.insight-detail-content .content-inner .text ul li:before {
  content: "●";
  position: absolute;
  left: 0;
  display: inline;
  margin-right: 1em;
  color: greyish-brown;
  font-size: 0.6em;
}
.insight-detail-content .content-inner .text ul li strong {
  width: 100%;
}
.insight-detail-content .content-inner .text_green {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
  color: #00a09b;
  margin-top: 47px;
}
@media all and (min-width: 1023px) {
  .insight-detail-content .content-inner .text_green {
    font-size: 2em;
    line-height: 1.5em;
  }
}
@media all and (min-width: 1023px) {
  .insight-detail-content .content-inner .text_green {
    margin-top: 75px;
  }
}
.insight-detail-content .content-inner .text_green .green-title {
  font-weight: bold;
  margin-bottom: -1em;
}
.insight-detail-content .content-inner .text_green .green-text p {
  padding: 23px 0 0 0;
}
.insight-detail-content .content-inner .quote {
  text-align: center;
  margin-top: 53px;
  margin-bottom: 20px;
  padding-top: 26px;
  padding-bottom: 32px;
  border-top: 2px solid #00a09b;
  border-bottom: 2px solid #00a09b;
}
@media all and (min-width: 1023px) {
  .insight-detail-content .content-inner .quote {
    padding-top: 57px;
    padding-bottom: 50px;
    margin-top: 75px;
    margin-bottom: 50px;
  }
}
.insight-detail-content .content-inner .quote .quote-text {
  font-family: "HarmonySans", sans-serif;
  font-size: 2.5em;
  font-weight: 400;
  line-height: 1.2em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .insight-detail-content .content-inner .quote .quote-text {
    font-size: 3em;
    line-height: 1.33em;
  }
}
.insight-detail-content .content-inner .quote .quote-sub {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
  margin-top: 12px;
}
@media all and (min-width: 1023px) {
  .insight-detail-content .content-inner .quote .quote-sub {
    font-size: 1.8em;
    line-height: 1.39em;
  }
}
@media all and (min-width: 1023px) {
  .insight-detail-content .content-inner .quote .quote-sub {
    margin-top: 25px;
  }
}
.insight-detail-content .content-inner .quote .quote-sub-sub {
  font-family: "HarmonySans", sans-serif;
  font-size: 1em;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 12px;
}
@media all and (min-width: 1023px) {
  .insight-detail-content .content-inner .quote .quote-sub-sub {
    font-size: 1.2em;
  }
}
@media all and (min-width: 1023px) {
  .insight-detail-content .content-inner .quote .quote-sub-sub {
    margin-top: 10px;
  }
}
.insight-detail-content .content-inner .image-wrapper {
  margin-top: 52px;
}
@media all and (min-width: 1023px) {
  .insight-detail-content .content-inner .image-wrapper {
    margin-top: 75px;
  }
}
.insight-detail-content .content-inner .image-wrapper.is_vertical {
  width: 100%;
}
.insight-detail-content .content-inner .image-wrapper.is_horizontal {
  width: 100%;
  left: 0;
}
@media all and (min-width: 1023px) {
  .insight-detail-content .content-inner .image-wrapper.is_horizontal {
    width: 140%;
    left: -20%;
  }
}
.insight-detail-content .content-inner .capture {
  margin-top: 18px;
  font-family: "HarmonySans", sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.25em;
  letter-spacing: normal;
  text-align: center;
}
@media all and (min-width: 1023px) {
  .insight-detail-content .content-inner .capture {
    font-size: 1.4em;
    line-height: 1.43em;
  }
}
@media all and (min-width: 1023px) {
  .insight-detail-content .content-inner .capture {
    margin-top: 25px;
  }
}
.insight-detail-content .content-inner .author-headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 2em;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: normal;
  color: #00a09b;
  margin-top: 50px;
  text-align: center;
}
@media all and (min-width: 1023px) {
  .insight-detail-content .content-inner .author-headline {
    text-align: left;
    max-width: 760px;
    margin: 50px auto 0 auto;
  }
}
.insight-detail-content .content-inner .module-author {
  margin-top: 10px;
  text-align: center;
}
@media all and (min-width: 1023px) {
  .insight-detail-content .content-inner .module-author {
    text-align: left;
    max-width: 760px;
    margin: 50px auto 0 auto;
  }
}
.insight-detail-content .content-inner .module-author .author {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
@media all and (min-width: 1023px) {
  .insight-detail-content .content-inner .module-author .author {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 28px;
  }
}
.insight-detail-content .content-inner .module-author .author .image-wrapper {
  margin-top: 22px;
  width: 140px;
  height: 140px;
  border-radius: 100%;
  overflow: hidden;
}
@media all and (min-width: 1023px) {
  .insight-detail-content .content-inner .module-author .author .image-wrapper {
    margin-right: 50px;
    flex: 0 1 auto;
    margin-top: 0;
  }
}
.insight-detail-content .content-inner .module-author .author .author-text-wrapper {
  margin-top: 26px;
  max-width: 300px;
}
@media all and (min-width: 1023px) {
  .insight-detail-content .content-inner .module-author .author .author-text-wrapper {
    max-width: unset;
    flex: 1;
    margin-top: 0;
  }
}
.insight-detail-content .content-inner .module-author .author .author-text {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.43em;
  letter-spacing: normal;
}
.insight-detail-content .content-inner .module-author .author .author-text.author-name {
  font-weight: 600;
}
.insight-detail-content .content-inner .module-author .author .author-text.author-desc {
  margin-top: 1.5em;
}

.intro-insights {
  padding-top: 99px;
  padding-bottom: 23px;
}
@media all and (min-width: 1023px) {
  .intro-insights {
    padding-top: 237px;
    padding-bottom: 72px;
  }
}
.intro-insights .inner-wrapper {
  display: flex;
  flex-direction: column;
}
.intro-insights .inner-wrapper .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 3em;
  font-weight: 400;
  line-height: 1.33em;
  letter-spacing: normal;
  max-width: 710px;
}
@media all and (min-width: 1023px) {
  .intro-insights .inner-wrapper .headline {
    font-size: 8em;
    line-height: 1.13em;
  }
}
.intro-insights .inner-wrapper .bottom-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 500px;
}
@media all and (min-width: 1400px) {
  .intro-insights .inner-wrapper .bottom-wrapper {
    max-width: unset;
    margin-top: 90px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.intro-insights .inner-wrapper .bottom-wrapper .filters {
  height: auto;
  width: 100%;
  order: 1;
  display: flex;
  flex-direction: column;
  margin-top: 21px;
}
@media all and (min-width: 1400px) {
  .intro-insights .inner-wrapper .bottom-wrapper .filters {
    margin-top: 0;
    order: 2;
    width: auto;
    flex-direction: row;
  }
}
.intro-insights .inner-wrapper .bottom-wrapper .filters .select-filter {
  margin-top: 10px;
}
@media all and (min-width: 1400px) {
  .intro-insights .inner-wrapper .bottom-wrapper .filters .select-filter {
    margin: 0 5px;
  }
}
.intro-insights .inner-wrapper .bottom-wrapper .filters .select-filter:first-child {
  margin-top: 0;
}
.intro-insights .inner-wrapper .bottom-wrapper .entries {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
  color: #00a09b;
  order: 2;
  margin-top: 22px;
}
@media all and (min-width: 1023px) {
  .intro-insights .inner-wrapper .bottom-wrapper .entries {
    font-size: 2em;
    line-height: 1.5em;
  }
}
@media all and (min-width: 1023px) {
  .intro-insights .inner-wrapper .bottom-wrapper .entries {
    margin-top: 42px;
  }
}
@media all and (min-width: 1400px) {
  .intro-insights .inner-wrapper .bottom-wrapper .entries {
    order: 1;
    margin-top: 0;
  }
}

.insights-overview {
  margin-top: 0;
  background-color: #f3f3f2;
  padding-top: 30px;
  padding-bottom: 100px;
}
@media all and (min-width: 1023px) {
  .insights-overview {
    padding-top: 0;
    padding-bottom: 120px;
  }
}
.insights-overview .insights-inner .insights {
  margin-top: 0;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
@media all and (min-width: 1023px) {
  .insights-overview .insights-inner .insights {
    margin: 0 -20px;
  }
}
.insights-overview .insights-inner .insights .empty-msg {
  display: none;
  font-family: "HarmonySans", sans-serif;
  font-size: 3em;
  font-weight: 400;
  line-height: 1.33em;
  letter-spacing: normal;
  margin-top: 0.6em;
}
@media all and (min-width: 1023px) {
  .insights-overview .insights-inner .insights .empty-msg {
    font-size: 6em;
    line-height: 1em;
  }
}
@media all and (min-width: 1023px) {
  .insights-overview .insights-inner .insights .empty-msg {
    margin-top: 1.5em;
  }
}
.insights-overview .insights-inner .insights .empty-msg.is-visible {
  display: block;
}
.insights-overview .insights-inner .insights .svg-circle {
  width: 70px;
  height: 70px;
  position: absolute;
  transform: rotate(45deg);
  backface-visibility: hidden;
  pointer-events: none;
  display: none;
  /*opacity: 0;*/
  /*animation: getSmaller 0.5s infinite 1s both;*/
  animation-name: loader;
  animation-duration: 2.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  /*animation-delay: 2s;*/
  left: 50%;
  top: 12em;
  transform: translate(-50%, 0);
  pointer-events: none;
}
.insights-overview .insights-inner .insights .svg-circle circle {
  fill: transparent;
}
.insights-overview .insights-inner .insights .svg-circle.is-visible {
  display: block;
}
.insights-overview .insights-inner .insights .insight-item-sizer {
  width: 100%;
  margin-top: 50px;
}
@media all and (min-width: 1023px) {
  .insights-overview .insights-inner .insights .insight-item-sizer {
    padding: 0 20px;
    width: 33%;
    margin-top: 75px;
  }
}
.insights-overview .insights-inner .insights .insight-stamp {
  width: 100vw;
  left: -8%;
  position: absolute;
  padding-top: 90px;
  padding-bottom: 83px;
  background-color: #ffffff;
  top: 800px;
}
.insights-overview .insights-inner .insights .insight-stamp .inner-wrapper {
  display: flex;
  flex-direction: column;
  width: 84%;
  margin: 0 auto;
}
@media all and (min-width: 1023px) {
  .insights-overview .insights-inner .insights .insight-stamp .inner-wrapper {
    flex-direction: row;
    align-items: center;
  }
}
.insights-overview .insights-inner .insights .insight-stamp .inner-wrapper .video-wrapper .svg,
.insights-overview .insights-inner .insights .insight-stamp .inner-wrapper .image-wrapper .svg {
  position: absolute;
  width: 70px;
  height: auto;
  z-index: 1;
  top: 0;
  left: 0;
  padding-top: 2em;
  padding-left: 1.5em;
  box-sizing: content-box;
}
@media all and (min-width: 1023px) and (max-width: 1400px) {
  .insights-overview .insights-inner .insights .insight-stamp .inner-wrapper .video-wrapper .svg,
.insights-overview .insights-inner .insights .insight-stamp .inner-wrapper .image-wrapper .svg {
    width: 50px;
  }
}
@media all and (min-width: 1023px) {
  .insights-overview .insights-inner .insights .insight-stamp .inner-wrapper .text-wrapper {
    max-width: 360px;
    margin-left: 40px;
  }
}
.insights-overview .insights-inner .insights .insight-stamp .inner-wrapper .title {
  font-family: "HarmonySans", sans-serif;
  font-size: 2.5em;
  font-weight: 400;
  line-height: 1.2em;
  letter-spacing: normal;
  margin-top: 26px;
}
@media all and (min-width: 1023px) {
  .insights-overview .insights-inner .insights .insight-stamp .inner-wrapper .title {
    font-size: 3em;
    line-height: 1.33em;
  }
}
.insights-overview .insights-inner .insights .insight-stamp .inner-wrapper .info-wrapper {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 2.08em;
  letter-spacing: normal;
  margin-top: 7px;
}
@media all and (min-width: 1023px) {
  .insights-overview .insights-inner .insights .insight-stamp .inner-wrapper .info-wrapper {
    margin-top: 0;
  }
}
.insights-overview .insights-inner .insights .insight-stamp .inner-wrapper .info-wrapper .author {
  opacity: 0.5;
}
.insights-overview .insights-inner .insights .insight-stamp .inner-wrapper .desc {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
  margin-top: 4px;
}
@media all and (min-width: 1023px) {
  .insights-overview .insights-inner .insights .insight-stamp .inner-wrapper .desc {
    font-size: 1.8em;
    line-height: 1.39em;
  }
}
@media all and (min-width: 1023px) {
  .insights-overview .insights-inner .insights .insight-stamp .inner-wrapper .desc {
    margin-top: 13px;
  }
}
.insights-overview .insights-inner .insights .insight-stamp .inner-wrapper button {
  margin-top: 17px;
}
.insights-overview .insights-inner .insights .insight-item {
  width: 100%;
  margin-top: 50px;
}
@media all and (min-width: 1023px) {
  .insights-overview .insights-inner .insights .insight-item {
    margin-top: 75px;
    margin-bottom: 25px;
    padding: 0 20px;
    width: 33%;
  }
}
.insights-overview .insights-inner .insights .insight-item .image-wrapper {
  height: auto;
  padding-bottom: 0;
  margin-bottom: 26px;
}
@media all and (min-width: 1023px) {
  .insights-overview .insights-inner .insights .insight-item .image-wrapper {
    margin-bottom: 19px;
  }
}
.insights-overview .insights-inner .insights .insight-item .image-wrapper img {
  position: relative;
  bottom: auto;
}
.intro {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.intro .image-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.intro.is-white .lines .line {
  background-color: #ffffff;
}
.intro .text-wrapper {
  position: absolute;
  z-index: 1;
  padding-left: 8%;
  top: 120px;
  color: #ffffff;
  max-width: 210px;
  box-sizing: content-box;
}
.intro .text-wrapper.is-white {
  color: #ffffff;
}
@media all and (min-width: 1023px) {
  .intro .text-wrapper {
    max-width: 400px;
    top: 50%;
    transform: translate(0, -50%);
  }
}
.intro .text-wrapper .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 4.5em;
  font-weight: 300;
  line-height: 1.11em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .intro .text-wrapper .headline {
    font-size: 8em;
    line-height: 0.88em;
  }
}
.intro .text-wrapper .subtext {
  margin-top: 13px;
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
  letter-spacing: 1px;
}
@media all and (min-width: 1023px) {
  .intro .text-wrapper .subtext {
    margin-top: 21px;
  }
}
@media all and (min-width: 1023px) {
  .intro .text-wrapper .subtext {
    font-size: 2em;
    line-height: 1.5em;
  }
}
.intro .text-wrapper button {
  margin-top: 23px;
}
@media all and (min-width: 1023px) {
  .intro .text-wrapper button {
    margin-top: 22px;
  }
}
.intro .svg-icn-arrow-down {
  position: absolute;
  bottom: 85px;
  left: 50%;
  transform: translate(-50%, 0);
  cursor: pointer;
  display: none;
  width: 48px;
  height: 48px;
}
.intro .svg-icn-arrow-down circle {
  fill: transparent;
  stroke: #ffffff;
}
.intro .svg-icn-arrow-down path {
  stroke: #ffffff;
}
.intro .svg-icn-arrow-down path,
.intro .svg-icn-arrow-down circle {
  transition: all 0.2s ease-in-out;
}
.intro .svg-icn-arrow-down:hover circle {
  fill: #ffffff;
  stroke: #ffffff;
}
.intro .svg-icn-arrow-down:hover path {
  stroke: #4c543e;
}
@media all and (min-width: 1023px) {
  .intro .svg-icn-arrow-down {
    display: block;
    width: 60px;
    height: 60px;
  }
}
.intro .svg-icn-signet {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 0;
  top: 0;
}
@media all and (min-width: 1023px) {
  .intro .svg-icn-signet {
    width: 50px;
    height: 50px;
  }
}
.intro-1 {
  width: 100%;
  height: auto;
  position: relative;
  padding-top: 100px;
  padding-bottom: 75px;
  background-color: #f3f3f2;
}
@media all and (min-width: 1023px) {
  .intro-1 {
    padding-top: 231px;
    padding-bottom: 182px;
  }
}
.intro-1 .lines {
  z-index: 2;
}
.intro-1 .text-wrapper {
  color: #4c543e;
  width: 84%;
  max-width: unset;
  position: relative;
  top: 0;
  z-index: 2;
  transform: translate(0, 0);
}
.intro-1 .text-wrapper .suptext {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.39em;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: none;
  max-width: 260px;
}
@media all and (min-width: 1023px) {
  .intro-1 .text-wrapper .suptext {
    font-size: 1.8em;
    letter-spacing: 3px;
  }
}
@media all and (min-width: 1023px) {
  .intro-1 .text-wrapper .suptext {
    display: block;
    max-width: 800px;
    margin-bottom: 12px;
  }
}
.intro-1 .text-wrapper .headline {
  max-width: 260px;
  font-family: "HarmonySans", sans-serif;
  font-size: 3em;
  font-weight: 400;
  line-height: 1.33em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .intro-1 .text-wrapper .headline {
    font-size: 6em;
    line-height: 1em;
  }
}
.intro-1 .text-wrapper .headline-large {
  font-family: "HarmonySans", sans-serif;
  font-size: 3em;
  font-weight: 400;
  line-height: 1.33em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .intro-1 .text-wrapper .headline-large {
    font-size: 8em;
    line-height: 1.13em;
  }
}
@media all and (min-width: 1023px) {
  .intro-1 .text-wrapper .headline {
    max-width: 800px;
  }
}
.intro-1 .text-wrapper .suptext + .headline + .svg-icn-signet {
  top: 0;
}
@media all and (min-width: 1023px) {
  .intro-1 .text-wrapper .suptext + .headline + .svg-icn-signet {
    top: 50px;
    width: 50px;
    height: 50px;
  }
}
@media all and (min-width: 1023px) {
  .intro-2 {
    height: auto;
  }
}
.intro-2 .lines {
  z-index: 2;
}
.intro-2 .text-wrapper {
  max-width: 700px;
  padding: 0 8%;
  z-index: 2;
  padding-top: 100px;
  padding-bottom: 70px;
  position: relative;
  top: 0;
  transform: unset;
  max-width: 400px;
}
@media all and (min-width: 1023px) {
  .intro-2 .text-wrapper {
    max-width: 700px;
    padding-top: 230px;
    padding-bottom: 200px;
  }
}
.intro-2 .text-wrapper .subtext {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
  margin-top: 15px;
  font-weight: 300;
}
@media all and (min-width: 1023px) {
  .intro-2 .text-wrapper .subtext {
    font-size: 2.5em;
    line-height: 1.2em;
  }
}
@media all and (min-width: 1023px) {
  .intro-2 .text-wrapper .subtext {
    margin-top: 38px;
  }
}
.intro-2 .text-wrapper .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 3em;
  font-weight: 400;
  line-height: 1.33em;
  letter-spacing: normal;
  font-weight: 300;
}
@media all and (min-width: 1023px) {
  .intro-2 .text-wrapper .headline {
    font-size: 4.5em;
    line-height: 1.11em;
  }
}
.intro-3 .text-wrapper .subtext {
  font-weight: 300;
}
.intro-4 .lines {
  z-index: 2;
}
.intro-4 .svg-icn-arrow-down {
  z-index: 2;
  display: block;
}
.intro-4 .text-wrapper {
  max-width: 280px;
  z-index: 2;
}
@media all and (min-width: 1023px) {
  .intro-4 .text-wrapper {
    max-width: 700px;
    top: auto;
    transform: unset;
    bottom: 140px;
  }
}
.intro-4 .text-wrapper .suptext {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.67em;
  letter-spacing: 2px;
  text-transform: uppercase;
}
@media all and (min-width: 1023px) {
  .intro-4 .text-wrapper .suptext {
    font-size: 1.8em;
    letter-spacing: 3px;
  }
}
.intro-4 .text-wrapper .headline {
  margin-top: 5px;
}
@media all and (min-width: 1023px) {
  .intro-4 .text-wrapper .headline {
    margin-top: 20px;
  }
}
.intro-contact {
  height: auto;
  padding-bottom: 31px;
}
@media all and (min-width: 1023px) {
  .intro-contact {
    padding-bottom: 132px;
  }
}
.intro-contact .text-wrapper {
  margin-top: 120px;
  position: relative;
  top: 0;
  transform: unset;
}
@media all and (min-width: 1023px) {
  .intro-contact .text-wrapper {
    margin-top: 221px;
    padding-bottom: 132px;
    padding-left: 14%;
  }
}
.intro-contact .text-wrapper .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 3em;
  font-weight: 400;
  line-height: 1.33em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .intro-contact .text-wrapper .headline {
    font-size: 4.5em;
    line-height: 1.11em;
  }
}
.intro-contact .text-wrapper .companyname {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
  font-weight: 600;
  margin-top: 47px;
  position: relative;
}
@media all and (min-width: 1023px) {
  .intro-contact .text-wrapper .companyname {
    font-size: 1.8em;
    line-height: 1.39em;
  }
}
@media all and (min-width: 1023px) {
  .intro-contact .text-wrapper .companyname {
    margin-top: 35px;
  }
  .intro-contact .text-wrapper .companyname:after {
    content: "";
    display: block;
    position: relative;
    width: 30px;
    height: 1px;
    background-color: #fff;
    margin-top: 0.6em;
  }
}
.intro-contact .text-wrapper .address {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
  margin-top: 11px;
}
@media all and (min-width: 1023px) {
  .intro-contact .text-wrapper .address {
    font-size: 1.8em;
    line-height: 1.39em;
  }
}
.intro-contact .text-wrapper .address p {
  margin: 1em 0;
}
.intro-contact .text-wrapper .address a {
  color: inherit;
}
.intro-contact .text-wrapper .uid {
  font-family: "HarmonySans", sans-serif;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: normal;
  margin-top: 57px;
  color: rgba(250, 250, 250, 0.71);
}
@media all and (min-width: 1023px) {
  .intro-contact .text-wrapper .uid {
    font-size: 1.2em;
    line-height: 1.67em;
    margin-top: 27px;
  }
}

.image-wrapper.image-after {
  position: relative;
  width: 100%;
  padding: 0 8%;
  margin: 0 auto;
  margin-top: -45px;
}
@media all and (min-width: 1023px) {
  .image-wrapper.image-after {
    margin-top: -90px;
  }
}
.image-wrapper.image-after .image-wrapper-bg {
  position: absolute;
  top: 45px;
  height: calc(100% - 45px);
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}
@media all and (min-width: 1023px) {
  .image-wrapper.image-after .image-wrapper-bg {
    top: 90px;
    height: calc(100% - 90px);
  }
}

.intro + .video-wrapper {
  position: relative;
  width: 84%;
  height: 0;
  padding-bottom: 47%;
  margin: 0 auto;
  margin-top: -45px;
}
.intro + .video-wrapper iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.intro + .video-wrapper iframe .ytp-chrome-top {
  display: none;
}
@media all and (min-width: 1023px) {
  .intro + .video-wrapper {
    margin-top: -90px;
  }
}

nav {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}
nav.nav-absolute {
  position: absolute;
  pointer-events: none;
}
nav.nav-absolute .nav-head {
  pointer-events: none;
}
nav.nav-absolute .nav-head a {
  pointer-events: all;
}
nav .nav-head {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  box-sizing: content-box;
  pointer-events: none;
}
@media all and (min-width: 1023px) {
  nav .nav-head {
    padding-top: 48px;
    height: 72px;
  }
}
nav .nav-head .back-wrapper,
nav .nav-head .burger-wrapper {
  margin-right: auto;
  padding: 1em;
  margin-left: -1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  pointer-events: all;
}
nav .nav-head .svg-icn-logo-hell {
  position: relative;
  width: 80px;
  height: 34px;
}
@media all and (min-width: 1023px) {
  nav .nav-head .svg-icn-logo-hell {
    width: 166px;
    height: 72px;
  }
}
nav .nav-head .text-right {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 600;
  line-height: 1.25em;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 300;
  margin-left: auto;
  opacity: 0;
  color: #ffffff;
  pointer-events: none;
  height: auto;
  display: flex;
}
@media all and (min-width: 1023px) {
  nav .nav-head .text-right {
    font-size: 1.6em;
  }
}
nav .nav-head .text-right svg {
  width: 12em;
  height: auto;
}
@media all and (min-width: 1023px) {
  nav .nav-head .text-right {
    opacity: 1;
  }
}
nav.is-scrolled {
  pointer-events: none;
}
nav.is-scrolled .burger-bg {
  opacity: 0.9;
}
nav.is-active .nav-head .burger-wrapper .burger-text-after {
  color: #ffffff !important;
}
nav.is-active .nav-head .svg-icn-logo-hell {
  display: none;
}
nav.is-active .nav-head .svg-icn-logo-hell path {
  fill: #ffffff !important;
}
@media all and (min-width: 1023px) {
  nav.is-active .nav-head .svg-icn-logo-hell {
    display: block;
  }
}
nav.is-active .nav-head .text-right {
  color: #ffffff !important;
}
nav.is-active .nav-head .text-right svg polygon,
nav.is-active .nav-head .text-right svg path {
  fill: #ffffff !important;
}
nav.is-active.is-scrolled .burger .burger-bg {
  opacity: 0;
}
nav.is-black .nav-head .burger-wrapper:before, nav.is-black .nav-head .burger-wrapper:after {
  background-color: #4c543e;
}
nav.is-black .nav-head .burger-wrapper .burger-text-after {
  color: #4c543e;
  font-weight: 600;
}
nav.is-black .nav-head .svg-icn-logo-hell path {
  fill: #4c543e;
}
nav.is-black .nav-head .text-right {
  color: #4c543e;
  font-weight: 600;
}
nav.is-black .nav-head .text-right svg polygon,
nav.is-black .nav-head .text-right svg path {
  fill: #4c543e;
}

.burger {
  width: 35px;
  height: 10px;
  position: relative;
}
.burger:before, .burger:after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  background-color: #ffffff;
  transition: all 0.15s ease-in-out;
}
.is-black .burger:before, .is-black .burger:after {
  background-color: #4c543e;
}
.burger:before {
  top: 0;
}
.burger:after {
  bottom: 0;
}
.is-active .burger:before {
  background-color: #ffffff;
  top: 50%;
  transform: rotate(45deg) translate(0, -50%);
}
.is-active .burger:after {
  background-color: #ffffff;
  bottom: 50%;
  transform: rotate(-45deg) translate(0, 50%);
}
.is-scrolled .burger:before, .is-scrolled .burger:after {
  background-color: #4c543e;
}
.is-active.is-scrolled .burger:before, .is-active.is-scrolled .burger:after {
  background-color: #ffffff;
}

.burger-bg {
  width: 56px;
  height: 56px;
  background-color: #ffffff;
  border-radius: 100%;
  /* transform: translate(-50%,-50%); */
  position: absolute;
  pointer-events: none;
  z-index: -1;
  top: -23px;
  left: -11px;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}
.arrow-button .burger-bg {
  top: -12px;
  left: -11px;
}

.back-button-after,
.burger-text-after {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.67em;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 300;
  color: #ffffff;
  margin-left: 1.2em;
  display: none;
  margin-top: 2px;
}
@media all and (min-width: 1023px) {
  .back-button-after,
.burger-text-after {
    font-size: 1.6em;
    line-height: 1.25em;
    letter-spacing: 3px;
  }
}
.is-active .back-button-after,
.is-active .burger-text-after {
  display: block;
}
.is-active .back-button-after.close,
.is-active .burger-text-after.close {
  display: block;
}
.back-button-after.title,
.burger-text-after.title {
  display: none;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}
@media all and (min-width: 1023px) {
  .back-button-after.title,
.burger-text-after.title {
    display: block;
  }
}
.is-active .back-button-after.title,
.is-active .burger-text-after.title {
  display: none;
}
.is-scrolled .back-button-after.title,
.is-scrolled .burger-text-after.title {
  opacity: 0;
  pointer-events: none;
}

.nav-head .arrow-button {
  transform: rotate(180deg);
}
.nav-head .arrow-button svg path {
  transition: all 0.25s ease-in-out;
  fill: #ffffff;
}
.is-scrolled .nav-head .arrow-button svg path {
  fill: #4c543e;
}

.nav-head .back-button-after {
  margin-top: 0.4em;
  display: none;
}
@media all and (min-width: 1023px) {
  .nav-head .back-button-after {
    display: block;
  }
}

.nav-main-wrapper {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  background-color: #4c543e;
  z-index: 998;
  overflow: auto;
  padding: 0 15%;
  opacity: 0;
  transform: translate(-50px, 0);
  pointer-events: none;
  transition: all 0.25s ease-in-out;
}
@media all and (min-width: 1023px) {
  .nav-main-wrapper {
    width: 50%;
    padding: 0 10%;
  }
}
.nav-main-wrapper.is-active {
  opacity: 1;
  transform: translate(0, 0);
  pointer-events: all;
}

.menu-header-menue-en-container .menu,
.menu-header-menue-de-container .menu {
  margin-top: 106px;
}
@media all and (min-width: 1023px) {
  .menu-header-menue-en-container .menu,
.menu-header-menue-de-container .menu {
    margin-top: 174px;
  }
}
.menu-header-menue-en-container .menu .menu-item,
.menu-header-menue-de-container .menu .menu-item {
  position: relative;
  color: rgba(250, 250, 250, 0.4);
}
.menu-header-menue-en-container .menu .menu-item.menu-item-has-children a:before, .menu-header-menue-en-container .menu .menu-item.menu-item-has-children a:after,
.menu-header-menue-de-container .menu .menu-item.menu-item-has-children a:before,
.menu-header-menue-de-container .menu .menu-item.menu-item-has-children a:after {
  content: "+";
  position: absolute;
  left: -1.1em;
  top: 0;
}
.menu-header-menue-en-container .menu .menu-item.menu-item-has-children a:after,
.menu-header-menue-de-container .menu .menu-item.menu-item-has-children a:after {
  content: "–";
  display: none;
}
.menu-header-menue-en-container .menu .menu-item.menu-item-has-children a.is-active,
.menu-header-menue-de-container .menu .menu-item.menu-item-has-children a.is-active {
  color: #ffffff;
}
.menu-header-menue-en-container .menu .menu-item.menu-item-has-children a.is-active:before,
.menu-header-menue-de-container .menu .menu-item.menu-item-has-children a.is-active:before {
  display: none;
}
.menu-header-menue-en-container .menu .menu-item.menu-item-has-children a.is-active:after,
.menu-header-menue-de-container .menu .menu-item.menu-item-has-children a.is-active:after {
  display: block;
}
.menu-header-menue-en-container .menu .menu-item.menu-item-has-children a.is-active + .sub-menu,
.menu-header-menue-de-container .menu .menu-item.menu-item-has-children a.is-active + .sub-menu {
  max-height: unset;
}
.menu-header-menue-en-container .menu .menu-item a,
.menu-header-menue-de-container .menu .menu-item a {
  font-family: "HarmonySans", sans-serif;
  font-size: 2.5em;
  font-weight: 400;
  line-height: 2em;
  letter-spacing: normal;
  color: inherit;
}
@media all and (min-width: 1023px) {
  .menu-header-menue-en-container .menu .menu-item a,
.menu-header-menue-de-container .menu .menu-item a {
    font-size: 4.5em;
    line-height: 1.33em;
  }
}
.menu-header-menue-en-container .menu .menu-item:hover,
.menu-header-menue-de-container .menu .menu-item:hover {
  color: #ffffff;
}
.menu-header-menue-en-container .menu .menu-item:hover a,
.menu-header-menue-de-container .menu .menu-item:hover a {
  color: inherit;
}
.menu-header-menue-en-container .menu .menu-item .sub-menu,
.menu-header-menue-de-container .menu .menu-item .sub-menu {
  max-height: 0;
  overflow: hidden;
}
.menu-header-menue-en-container .menu .menu-item .sub-menu .menu-item a,
.menu-header-menue-de-container .menu .menu-item .sub-menu .menu-item a {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 2.14em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .menu-header-menue-en-container .menu .menu-item .sub-menu .menu-item a,
.menu-header-menue-de-container .menu .menu-item .sub-menu .menu-item a {
    font-size: 2em;
    line-height: 1.75em;
  }
}
.menu-header-menue-en-container .menu .menu-item .sub-menu .menu-item a:hover,
.menu-header-menue-de-container .menu .menu-item .sub-menu .menu-item a:hover {
  color: #ffffff;
}

.nav-open-overlay {
  position: absolute;
  width: 100%;
  z-index: 2;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  pointer-events: none;
  transition: all 0.15s ease-in-out;
}
.nav-open-overlay.is-active {
  pointer-events: all;
  opacity: 1;
}

.lang-switcher {
  margin-top: 38px;
  margin-bottom: 5em;
}
@media all and (min-width: 1023px) {
  .lang-switcher {
    margin-top: 42px;
    margin-bottom: 7em;
  }
}
.lang-switcher .lang {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.8em;
  font-weight: 400;
  line-height: 1.11em;
  letter-spacing: normal;
  color: rgba(250, 250, 250, 0.4);
  padding-right: 0;
}
@media all and (min-width: 1023px) {
  .lang-switcher .lang {
    font-size: 2.5em;
    line-height: 1.2em;
  }
}
.lang-switcher .lang:hover {
  color: #ffffff;
}
.lang-switcher .lang.is-inactive {
  color: #00a09b;
  pointer-events: none;
}
.lang-switcher .lang:first-child {
  padding-right: 11px;
  padding-left: 0;
}
.lang-switcher .seperator {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.8em;
  font-weight: 400;
  line-height: 1.11em;
  letter-spacing: normal;
  color: rgba(250, 250, 250, 0.4);
}
@media all and (min-width: 1023px) {
  .lang-switcher .seperator {
    font-size: 2.5em;
    line-height: 1.2em;
  }
}

.section-Accordion {
  background-color: #f3f3f2;
  padding-bottom: 59px;
}
@media all and (min-width: 1023px) {
  .section-Accordion {
    padding-bottom: 100px;
  }
}
.section-Accordion.no_headline .inner-wrapper .head-wrapper {
  display: none;
}
.section-Accordion .inner-wrapper .head-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 31px;
}
@media all and (min-width: 1023px) {
  .section-Accordion .inner-wrapper .head-wrapper {
    padding: 0 8% 100px 8%;
  }
}
.section-Accordion .inner-wrapper .head-wrapper .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 3em;
  font-weight: 400;
  line-height: 1.33em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .section-Accordion .inner-wrapper .head-wrapper .headline {
    font-size: 6em;
    line-height: 1em;
  }
}
.section-Accordion .inner-wrapper .head-wrapper .svg-icn-signet {
  width: 39px;
  height: 39px;
}
@media all and (min-width: 1023px) {
  .section-Accordion .inner-wrapper .head-wrapper .svg-icn-signet {
    width: 50px;
    height: 50px;
  }
}
.section-Accordion .inner-wrapper .accordion .accordion-item {
  border-top: 1px solid #4c543e;
  display: block;
}
.section-Accordion .inner-wrapper .accordion .accordion-item:last-of-type {
  border-bottom: 1px solid #4c543e;
}
.section-Accordion .inner-wrapper .accordion .accordion-item:hover {
  background-color: #e7e7e6;
}
.section-Accordion .inner-wrapper .accordion .accordion-item .accordion-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 98px;
  padding: 3em 0;
  cursor: pointer;
  position: relative;
}
@media all and (min-width: 1023px) {
  .section-Accordion .inner-wrapper .accordion .accordion-item .accordion-head {
    height: auto;
    min-height: auto;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 5em 0;
    width: 100%;
    margin: 0 auto;
  }
}
.section-Accordion .inner-wrapper .accordion .accordion-item .accordion-head .title {
  font-family: "HarmonySans", sans-serif;
  font-size: 2.5em;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: normal;
  order: 1;
  width: calc(100% - 50px);
  display: flex;
}
@media all and (min-width: 1023px) {
  .section-Accordion .inner-wrapper .accordion .accordion-item .accordion-head .title {
    font-size: 4.5em;
    line-height: 1.11em;
    letter-spacing: -1px;
  }
}
@media all and (min-width: 1023px) {
  .section-Accordion .inner-wrapper .accordion .accordion-item .accordion-head .title {
    padding-right: 10%;
    margin-right: auto;
    order: 1;
    max-width: 580px;
  }
}
.section-Accordion .inner-wrapper .accordion .accordion-item .accordion-head .title .title-text {
  margin-left: 20px;
}
@media all and (min-width: 1023px) {
  .section-Accordion .inner-wrapper .accordion .accordion-item .accordion-head .title .title-text {
    margin-left: 35px;
  }
}
.section-Accordion .inner-wrapper .accordion .accordion-item .accordion-head .teasertext {
  font-family: "HarmonySans", sans-serif;
  font-size: 2em;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: normal;
  display: none;
  max-width: 320px;
  order: 3;
}
@media all and (min-width: 1023px) {
  .section-Accordion .inner-wrapper .accordion .accordion-item .accordion-head .teasertext {
    display: block;
    margin-right: 10%;
    margin-top: 0.8em;
    order: 2;
  }
}
.section-Accordion .inner-wrapper .accordion .accordion-item .accordion-head .svg-icn-open-to-close {
  margin-right: 1em;
  order: 2;
  top: 5em;
  right: 0;
}
@media all and (min-width: 1023px) {
  .section-Accordion .inner-wrapper .accordion .accordion-item .accordion-head .svg-icn-open-to-close {
    position: absolute;
    top: 0;
    margin-top: 5em;
    order: 3;
  }
}
.section-Accordion .inner-wrapper .accordion .accordion-item .content {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
  max-height: 0;
  overflow: hidden;
}
@media all and (min-width: 1023px) {
  .section-Accordion .inner-wrapper .accordion .accordion-item .content {
    font-size: 2em;
    line-height: 1.5em;
  }
}
@media all and (min-width: 1023px) {
  .section-Accordion .inner-wrapper .accordion .accordion-item .content {
    max-height: 0;
    overflow: hidden;
    margin: 0 auto;
    height: auto;
  }
}
@media all and (min-width: 1023px) {
  .section-Accordion .inner-wrapper .accordion .accordion-item .content .content-inner {
    margin-bottom: 180px;
  }
}
.section-Accordion .inner-wrapper .accordion .accordion-item .content p {
  padding: 0.4em 0;
}
@media all and (min-width: 1023px) {
  .section-Accordion .inner-wrapper .accordion .accordion-item .content p {
    padding: 1em 0;
  }
}
.section-Accordion .inner-wrapper .accordion .accordion-item .content ul li {
  display: flex;
}
.section-Accordion .inner-wrapper .accordion .accordion-item .content ul li:before {
  content: "●";
  position: relative;
  display: inline;
  margin-right: 1em;
  color: greyish-brown;
  font-size: 0.6em;
}
.section-Accordion .inner-wrapper .accordion .accordion-item button {
  font-size: 10px;
  margin-top: 3.2em;
}

/*==================================
=            set active            =
==================================*/
.section-Accordion .inner-wrapper .accordion .accordion-item.is-active {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f3f3f2;
  width: 100%;
  height: 100vh;
  z-index: 9;
  padding: 37px 8% 91px 8%;
  max-height: unset;
  overflow: auto;
  border-top: 1px solid #00a09b;
}
@media all and (min-width: 1023px) {
  .section-Accordion .inner-wrapper .accordion .accordion-item.is-active {
    height: auto;
    background-color: #e7e7e6;
    top: auto;
    left: auto;
    position: relative;
    padding: 0 8%;
  }
}
.section-Accordion .inner-wrapper .accordion .accordion-item.is-active .accordion-head {
  color: #00a09b;
  flex-wrap: wrap;
  height: auto;
  padding: 0 0;
  min-height: auto;
}
@media all and (min-width: 1023px) {
  .section-Accordion .inner-wrapper .accordion .accordion-item.is-active .accordion-head {
    height: auto;
    justify-content: flex-end;
    align-items: flex-start;
    flex-wrap: unset;
    padding: 5em 0;
  }
}
.section-Accordion .inner-wrapper .accordion .accordion-item.is-active .accordion-head .title {
  order: 1;
  min-width: 50%;
}
@media all and (min-width: 1023px) {
  .section-Accordion .inner-wrapper .accordion .accordion-item.is-active .accordion-head .title {
    min-width: unset;
  }
}
.section-Accordion .inner-wrapper .accordion .accordion-item.is-active .accordion-head .teasertext {
  order: 3;
  display: block;
  margin-top: 20px;
}
@media all and (min-width: 1023px) {
  .section-Accordion .inner-wrapper .accordion .accordion-item.is-active .accordion-head .teasertext {
    order: 2;
    margin-top: 0.8em;
  }
}
.section-Accordion .inner-wrapper .accordion .accordion-item.is-active .accordion-head .svg-icn-open-to-close {
  order: 2;
}
@media all and (min-width: 1023px) {
  .section-Accordion .inner-wrapper .accordion .accordion-item.is-active .accordion-head .svg-icn-open-to-close {
    order: 3;
  }
}
.section-Accordion .inner-wrapper .accordion .accordion-item.is-active .content {
  max-height: unset;
  margin-top: 0.6em;
  overflow: visible;
}
@media all and (min-width: 1023px) {
  .section-Accordion .inner-wrapper .accordion .accordion-item.is-active .content {
    margin-top: 0;
  }
}

.section-Contact {
  background-color: #f3f3f2;
  padding-top: 59px;
  padding-bottom: 52px;
}
@media all and (min-width: 1023px) {
  .section-Contact {
    padding-top: 102px;
    padding-bottom: 127px;
  }
}
.section-Contact .inner-wrapper .headline-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media all and (min-width: 1023px) {
  .section-Contact .inner-wrapper .headline-wrapper {
    margin-bottom: 30px;
  }
}
.section-Contact .inner-wrapper .headline-wrapper .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 3em;
  font-weight: 400;
  line-height: 1.33em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .section-Contact .inner-wrapper .headline-wrapper .headline {
    font-size: 4.5em;
    line-height: 1.11em;
  }
}
.section-Contact .inner-wrapper .headline-wrapper svg {
  width: 40px;
  height: 40px;
}
@media all and (min-width: 1023px) {
  .section-Contact .inner-wrapper .headline-wrapper svg {
    width: 50px;
    height: 50px;
  }
}
.section-Contact .inner-wrapper .contacts {
  display: flex;
  flex-wrap: wrap;
}
@media all and (min-width: 1023px) {
  .section-Contact .inner-wrapper .contacts {
    margin: 0 -20px;
  }
}
.section-Contact .inner-wrapper .contacts .contact-item {
  width: 100%;
  margin-top: 51px;
}
.section-Contact .inner-wrapper .contacts .contact-item:first-child {
  margin-top: 51px;
}
@media all and (min-width: 1023px) {
  .section-Contact .inner-wrapper .contacts .contact-item {
    padding: 0 20px;
    width: 33%;
    margin-top: 50px;
  }
  .section-Contact .inner-wrapper .contacts .contact-item:first-child {
    margin-top: 50px;
  }
}
.section-Contact .inner-wrapper .contacts .contact-item .title {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
  font-weight: 600;
  margin-top: 17px;
}
@media all and (min-width: 1023px) {
  .section-Contact .inner-wrapper .contacts .contact-item .title {
    font-size: 1.8em;
    line-height: 1.39em;
  }
}
.section-Contact .inner-wrapper .contacts .contact-item .name {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .section-Contact .inner-wrapper .contacts .contact-item .name {
    font-size: 1.8em;
    line-height: 1.39em;
  }
}
.section-Contact .inner-wrapper .contacts .contact-item button {
  margin-top: 13px;
}

.section-ExpertsSlider {
  background-color: #f3f3f2;
  overflow: hidden;
}
.section-ExpertsSlider .inner-wrapper .headline-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section-ExpertsSlider .inner-wrapper .headline-wrapper .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 3em;
  font-weight: 400;
  line-height: 1.33em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .section-ExpertsSlider .inner-wrapper .headline-wrapper .headline {
    font-size: 6em;
    line-height: 1em;
  }
}
.section-ExpertsSlider .inner-wrapper .headline-wrapper .svg-icn-signet {
  width: 40px;
  height: 40px;
  margin-right: 8%;
}
@media all and (min-width: 1023px) {
  .section-ExpertsSlider .inner-wrapper .headline-wrapper .svg-icn-signet {
    width: 50px;
    height: 50px;
  }
}
.section-ExpertsSlider .inner-wrapper .slider-experts {
  overflow: hidden;
  margin-top: 41px;
}
@media all and (min-width: 1023px) {
  .section-ExpertsSlider .inner-wrapper .slider-experts {
    margin-top: 100px;
  }
}
.section-ExpertsSlider .inner-wrapper .slider-experts .expert-item {
  width: 41%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-right: 5%;
}
.section-ExpertsSlider .inner-wrapper .slider-experts .expert-item:nth-child(2n) {
  margin-right: 15%;
}
@media all and (max-width: 768px) {
  .section-ExpertsSlider .inner-wrapper .slider-experts .expert-item {
    margin-right: 10%;
    width: 80%;
  }
  .section-ExpertsSlider .inner-wrapper .slider-experts .expert-item:nth-child(2n) {
    margin-right: 10%;
  }
}
@media all and (min-width: 1023px) {
  .section-ExpertsSlider .inner-wrapper .slider-experts .expert-item {
    margin-right: 5%;
  }
  .section-ExpertsSlider .inner-wrapper .slider-experts .expert-item:nth-child(2n) {
    margin-right: 15%;
  }
}
.section-ExpertsSlider .inner-wrapper .slider-experts .expert-item .subtext-wrapper {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
  margin-top: 30px;
}
@media all and (min-width: 1023px) {
  .section-ExpertsSlider .inner-wrapper .slider-experts .expert-item .subtext-wrapper {
    font-size: 1.8em;
    line-height: 1.39em;
  }
}
.section-ExpertsSlider .inner-wrapper .slider-experts .expert-item .subtext-wrapper .name {
  font-weight: 600;
}
.section-ExpertsSlider .inner-wrapper .slider-experts .expert-item button {
  margin-top: 20px;
}
.section-ExpertsSlider .slider-buttons {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 50%;
  transform: translate(0, -50%);
  pointer-events: none;
  z-index: 1;
}
@media all and (max-width: 768px) {
  .section-ExpertsSlider .slider-buttons {
    display: none;
  }
}
.section-ExpertsSlider .slider-buttons .slider-button {
  height: 109px;
  background-color: #ffffff;
  cursor: pointer;
  pointer-events: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 109px;
}
.section-ExpertsSlider .slider-buttons .slider-button-prev {
  border-radius: 0 150% 150% 0;
  transform: translate(-32px, 0px);
}
.section-ExpertsSlider .slider-buttons .slider-button-prev svg {
  transform: translate(12px, 0px) rotate(180deg);
}
.section-ExpertsSlider .slider-buttons .slider-button-next {
  border-radius: 150% 0 0 150%;
  transform: translate(32px, 0px);
}
.section-ExpertsSlider .slider-buttons .slider-button-next svg {
  transform: translate(-12px, 0px);
}
.section-ExpertsSlider .slider-buttons .slider-button.is-hidden {
  opacity: 0;
  pointer-events: none;
}

.section-Gallery .inner-wrapper {
  display: flex;
  flex-direction: column;
}
@media all and (min-width: 1023px) {
  .section-Gallery .inner-wrapper {
    display: block;
  }
}
.section-Gallery .inner-wrapper .image-wrapper-hori {
  height: 0;
  width: 100%;
  padding-bottom: 63%;
}
@media all and (min-width: 1023px) {
  .section-Gallery .inner-wrapper .image-wrapper-hori {
    width: 48.5%;
    float: left;
    padding-bottom: 30%;
  }
}
.section-Gallery .inner-wrapper .image-wrapper-vert {
  height: 0;
  flex: 1;
  padding-bottom: 65%;
}
@media all and (min-width: 1023px) {
  .section-Gallery .inner-wrapper .image-wrapper-vert {
    flex: 1 48.5%;
  }
}
.section-Gallery .inner-wrapper .image-wrapper-vert-1 {
  margin-right: 10px;
}
@media all and (min-width: 1023px) {
  .section-Gallery .inner-wrapper .image-wrapper-vert-1 {
    margin-right: 3%;
  }
}
.section-Gallery .inner-wrapper .image-wrapper-vert-2 {
  margin-left: 10px;
}
@media all and (min-width: 1023px) {
  .section-Gallery .inner-wrapper .image-wrapper-vert-2 {
    margin-left: 3%;
  }
}
.section-Gallery .inner-wrapper .image-wrapper-vert-large {
  height: 0;
  width: 100%;
  padding-bottom: 133%;
  margin-top: 20px;
  order: 3;
}
@media all and (min-width: 1023px) {
  .section-Gallery .inner-wrapper .image-wrapper-vert-large {
    width: 48.5%;
    padding-bottom: 64.6%;
    float: right;
    margin-top: 0;
  }
}
.section-Gallery .inner-wrapper .image-item {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  order: 2;
}
@media all and (min-width: 1023px) {
  .section-Gallery .inner-wrapper .image-item {
    width: 48.5%;
    float: left;
    margin-top: 3%;
  }
}

.section-FourLogos .inner-wrapper .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 3em;
  font-weight: 400;
  line-height: 1.33em;
  letter-spacing: normal;
  text-align: center;
}
@media all and (min-width: 1023px) {
  .section-FourLogos .inner-wrapper .headline {
    font-size: 6em;
    line-height: 1em;
  }
}
.section-FourLogos .inner-wrapper .logos {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
@media all and (min-width: 1023px) {
  .section-FourLogos .inner-wrapper .logos {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    margin-top: 113px;
  }
}
.section-FourLogos .inner-wrapper .logos .flickity-viewport {
  width: 100%;
}
.section-FourLogos .inner-wrapper .logos .logo-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  padding: 0 4%;
  width: 100%;
}
@media all and (min-width: 1023px) {
  .section-FourLogos .inner-wrapper .logos .logo-item {
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    padding: 0 4%;
    justify-content: flex-end;
    min-height: 100%;
    width: 24%;
  }
}
.section-FourLogos .inner-wrapper .logos .logo-item .logo-item-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.section-FourLogos .inner-wrapper .logos .logo-item .logo {
  width: 110px;
}
@media all and (min-width: 1023px) {
  .section-FourLogos .inner-wrapper .logos .logo-item .logo {
    width: 110px;
    height: auto;
    margin-top: auto;
  }
}
.section-FourLogos .inner-wrapper .logos .logo-item .text-wrapper {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
  text-align: center;
  margin-top: 40px;
}
@media all and (min-width: 1023px) {
  .section-FourLogos .inner-wrapper .logos .logo-item .text-wrapper {
    font-size: 1.8em;
    line-height: 1.39em;
  }
}
@media all and (min-width: 1023px) {
  .section-FourLogos .inner-wrapper .logos .logo-item .text-wrapper {
    margin-top: auto;
    margin-top: 1.5em;
  }
}
.section-FourLogos .inner-wrapper .logos .logo-item .text-wrapper .linktext {
  font-weight: bold;
  display: block;
}
.section-FourLogos .inner-wrapper .slider-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4em;
}
@media all and (min-width: 1023px) {
  .section-FourLogos .inner-wrapper .slider-buttons {
    margin-top: 4em;
  }
}
.section-FourLogos .inner-wrapper .slider-buttons .slider-button {
  padding: 1.5em;
  cursor: pointer;
}
.section-FourLogos .inner-wrapper .slider-buttons .slider-button-prev {
  transform: rotate(180deg);
}
.section-FourLogos .inner-wrapper .slider-buttons .slider-button-next {
  margin-top: 0.5em;
}

.section-HeadlineImg .inner-wrapper .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 3em;
  font-weight: 400;
  line-height: 1.33em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .section-HeadlineImg .inner-wrapper .headline {
    font-size: 4.5em;
    line-height: 1.11em;
  }
}
@media all and (min-width: 1023px) {
  .section-HeadlineImg .inner-wrapper .headline {
    position: absolute;
    z-index: 1;
    top: 30%;
  }
}
.section-HeadlineImg .inner-wrapper .image-wrapper {
  margin-top: 21px;
}
@media all and (min-width: 1023px) {
  .section-HeadlineImg .inner-wrapper .image-wrapper {
    margin-top: 0;
  }
}

.section-Imagequote .inner-wrapper .quote-item {
  display: flex;
  flex-direction: column;
}
@media all and (min-width: 1023px) {
  .section-Imagequote .inner-wrapper .quote-item {
    flex-direction: row;
  }
}
.section-Imagequote .inner-wrapper .quote-item .quote-text-wrapper {
  flex: 1;
  color: #00a09b;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  order: 2;
  padding: 47px 0 42px 0;
}
@media all and (min-width: 1023px) {
  .section-Imagequote .inner-wrapper .quote-item .quote-text-wrapper {
    order: 1;
    padding: 0;
  }
}
.section-Imagequote .inner-wrapper .quote-item .quote-text-wrapper .quote-text {
  font-family: "HarmonySans", sans-serif;
  font-size: 2.5em;
  font-weight: 400;
  line-height: 1.2em;
  letter-spacing: normal;
  width: 86%;
  max-width: 500px;
}
@media all and (min-width: 1023px) {
  .section-Imagequote .inner-wrapper .quote-item .quote-text-wrapper .quote-text {
    font-size: 3em;
    line-height: 1.33em;
  }
}
.section-Imagequote .inner-wrapper .quote-item .quote-text-wrapper .quote-subtext {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
  margin-top: 10px;
  max-width: 500px;
  width: 86%;
}
@media all and (min-width: 1023px) {
  .section-Imagequote .inner-wrapper .quote-item .quote-text-wrapper .quote-subtext {
    font-size: 1.8em;
    line-height: 1.39em;
  }
}
@media all and (min-width: 1023px) {
  .section-Imagequote .inner-wrapper .quote-item .quote-text-wrapper .quote-subtext {
    margin-top: 42px;
  }
}
.section-Imagequote .inner-wrapper .quote-item .image-wrapper {
  flex: 1;
  height: 0;
  padding-bottom: 69%;
  order: 1;
}
@media all and (min-width: 1023px) {
  .section-Imagequote .inner-wrapper .quote-item .image-wrapper {
    padding-bottom: 34%;
    order: 2;
  }
}
.section-Imagequote .inner-wrapper .quote-item.is-mirrored .quote-text-wrapper {
  order: 2;
  color: #ffffff;
  background-color: #00a09b;
}
.section-Imagequote .inner-wrapper .quote-item.is-mirrored .image-wrapper {
  order: 1;
}

.section-ImgText .inner-wrapper .module-headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.67em;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-transform: uppercase;
  margin-bottom: 25px;
}
@media all and (min-width: 1023px) {
  .section-ImgText .inner-wrapper .module-headline {
    font-size: 1.8em;
    letter-spacing: 3px;
  }
}
@media all and (min-width: 1023px) {
  .section-ImgText .inner-wrapper .module-headline {
    margin-bottom: 53px;
  }
}
.section-ImgText .inner-wrapper .content-wrapper {
  display: flex;
  flex-direction: column;
}
@media all and (min-width: 1023px) {
  .section-ImgText .inner-wrapper .content-wrapper {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.section-ImgText .inner-wrapper .image-wrapper {
  margin-bottom: 19px;
  width: 100%;
  max-width: 400px;
}
@media all and (min-width: 1023px) {
  .section-ImgText .inner-wrapper .image-wrapper {
    width: 43.8%;
    margin-bottom: 0;
    max-width: unset;
  }
}
.section-ImgText .inner-wrapper .text-wrapper {
  width: 100%;
}
@media all and (min-width: 1023px) {
  .section-ImgText .inner-wrapper .text-wrapper {
    width: 43.8%;
  }
}
.section-ImgText .inner-wrapper .text-wrapper .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 3em;
  font-weight: 400;
  line-height: 1.33em;
  letter-spacing: normal;
  margin-bottom: 13px;
}
@media all and (min-width: 1023px) {
  .section-ImgText .inner-wrapper .text-wrapper .headline {
    font-size: 4.5em;
    line-height: 1.11em;
  }
}
@media all and (min-width: 1023px) {
  .section-ImgText .inner-wrapper .text-wrapper .headline {
    margin-bottom: 21px;
  }
}
.section-ImgText .inner-wrapper .text-wrapper .text {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
  margin-bottom: 23px;
}
@media all and (min-width: 1023px) {
  .section-ImgText .inner-wrapper .text-wrapper .text {
    font-size: 2em;
    line-height: 1.5em;
  }
}
@media all and (min-width: 1023px) {
  .section-ImgText .inner-wrapper .text-wrapper button {
    margin-bottom: 0;
  }
}
.section-ImgText.is_turquoise {
  color: #00a09b;
}
@media all and (min-width: 1023px) {
  .section-ImgText.is_horizontal .inner-wrapper .image-wrapper {
    width: 40%;
  }
}
@media all and (min-width: 1023px) {
  .section-ImgText.is_horizontal .inner-wrapper .text-wrapper {
    width: 40%;
  }
}
@media all and (min-width: 1023px) {
  .section-ImgText.is_mirrored .image-wrapper {
    order: 2;
  }
}
@media all and (min-width: 1023px) {
  .section-ImgText.is_mirrored .text-wrapper {
    order: 1;
  }
}

.section-Info {
  background: #f3f3f2;
}
.section-Info .inner-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
@media all and (min-width: 1023px) {
  .section-Info .inner-wrapper {
    display: block;
    margin: 0;
  }
}
.section-Info .inner-wrapper .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 3em;
  font-weight: 400;
  line-height: 1.33em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .section-Info .inner-wrapper .headline {
    font-size: 6em;
    line-height: 1em;
  }
}
@media all and (min-width: 1023px) {
  .section-Info .inner-wrapper .headline {
    float: left;
  }
}
.section-Info .inner-wrapper .column-left {
  order: 2;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media all and (min-width: 1023px) {
  .section-Info .inner-wrapper .column-left {
    width: 47%;
    float: left;
    display: block;
  }
}
.section-Info .inner-wrapper .column-right {
  order: 3;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media all and (min-width: 1023px) {
  .section-Info .inner-wrapper .column-right {
    display: block;
    width: 47%;
    float: right;
  }
}
.section-Info .inner-wrapper .info-item {
  margin-top: 50px;
  max-width: 500px;
}
@media all and (min-width: 1023px) {
  .section-Info .inner-wrapper .info-item {
    max-width: unset;
  }
}
.section-Info .inner-wrapper .info-item .title {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 600;
  line-height: 1.56em;
  letter-spacing: normal;
  margin-top: 22px;
}
@media all and (min-width: 1023px) {
  .section-Info .inner-wrapper .info-item .title {
    font-size: 2em;
  }
}
.section-Info .inner-wrapper .info-item .text {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
  margin-top: 5px;
}
@media all and (min-width: 1023px) {
  .section-Info .inner-wrapper .info-item .text {
    font-size: 2em;
    line-height: 1.5em;
  }
}
.section-Info .inner-wrapper .info-item button {
  margin-top: 22px;
}
@media all and (min-width: 1023px) {
  .section-Info .inner-wrapper .info-item {
    margin-top: 80px;
  }
}
@media all and (min-width: 1023px) {
  .section-Info .inner-wrapper .info-item-1 {
    margin-top: 110px;
  }
}

.section-InsightsTeaser {
  padding-top: 23px;
  padding-bottom: 60px;
}
@media all and (min-width: 1023px) {
  .section-InsightsTeaser {
    padding-top: 70px;
    padding-bottom: 60px;
  }
}
.section-InsightsTeaser .inner-wrapper .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.67em;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding-bottom: 22px;
}
@media all and (min-width: 1023px) {
  .section-InsightsTeaser .inner-wrapper .headline {
    font-size: 1.8em;
    letter-spacing: 3px;
  }
}
.section-InsightsTeaser .inner-wrapper .insights-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
}
@media all and (min-width: 1023px) {
  .section-InsightsTeaser .inner-wrapper .insights-wrapper {
    display: block;
  }
}
.section-InsightsTeaser .inner-wrapper .insights-wrapper .insight {
  margin-bottom: 60px;
}
.section-InsightsTeaser .inner-wrapper .insights-wrapper .insight:nth-child(3) {
  margin-bottom: 0;
}
@media all and (min-width: 1023px) {
  .section-InsightsTeaser .inner-wrapper .insights-wrapper .insight {
    width: 40%;
    max-width: unset;
  }
  .section-InsightsTeaser .inner-wrapper .insights-wrapper .insight:nth-child(1) {
    float: left;
  }
  .section-InsightsTeaser .inner-wrapper .insights-wrapper .insight:nth-child(2) {
    margin-top: -25em;
  }
  .section-InsightsTeaser .inner-wrapper .insights-wrapper .insight:nth-child(2), .section-InsightsTeaser .inner-wrapper .insights-wrapper .insight:nth-child(3) {
    float: right;
    margin-right: 8.6%;
  }
  .section-InsightsTeaser .inner-wrapper .insights-wrapper .insight:nth-child(3) {
    margin-left: auto;
    margin-right: 0;
    float: right;
    clear: right;
    width: 48.5%;
  }
  .section-InsightsTeaser .inner-wrapper .insights-wrapper .insight:nth-child(3) .title,
.section-InsightsTeaser .inner-wrapper .insights-wrapper .insight:nth-child(3) .teaser {
    width: 80%;
  }
}
.section-InsightsTeaser .inner-wrapper .insights-wrapper .insight .image-wrapper {
  margin-bottom: 23px;
}
@media all and (min-width: 1023px) {
  .section-InsightsTeaser .inner-wrapper .insights-wrapper .insight .image-wrapper {
    max-width: unset;
    margin-bottom: 28px;
  }
}
.section-InsightsTeaser .inner-wrapper .insights-wrapper .insight .title {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 600;
  line-height: 1.56em;
  letter-spacing: normal;
  margin-bottom: 4px;
}
@media all and (min-width: 1023px) {
  .section-InsightsTeaser .inner-wrapper .insights-wrapper .insight .title {
    font-size: 2em;
  }
}
@media all and (min-width: 1023px) {
  .section-InsightsTeaser .inner-wrapper .insights-wrapper .insight .title {
    margin-bottom: 10px;
  }
}
.section-InsightsTeaser .inner-wrapper .insights-wrapper .insight .teaser {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
  margin-bottom: 23px;
}
@media all and (min-width: 1023px) {
  .section-InsightsTeaser .inner-wrapper .insights-wrapper .insight .teaser {
    font-size: 2em;
    line-height: 1.5em;
  }
}

.section-InsightsTeaserTwo {
  background-color: #f3f3f2;
}
.section-InsightsTeaserTwo .inner-wrapper .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 3em;
  font-weight: 400;
  line-height: 1.33em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .section-InsightsTeaserTwo .inner-wrapper .headline {
    font-size: 4.5em;
    line-height: 1.11em;
  }
}
.section-InsightsTeaserTwo .inner-wrapper .insights .insight-item .image-wrapper {
  padding-bottom: 0;
}
@media all and (min-width: 1023px) {
  .section-InsightsTeaserTwo .inner-wrapper .insights .insight-item .image-wrapper {
    padding-bottom: 100%;
  }
}
.section-InsightsTeaserTwo .inner-wrapper .insights .insight-item .image-wrapper image {
  height: 100%;
  object-fit: cover;
}

.section-Map .inner-wrapper {
  width: 100%;
}
.section-Map .inner-wrapper #gmaps {
  padding-bottom: 42%;
}

.section-Numbers .inner-wrapper .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.67em;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
}
@media all and (min-width: 1023px) {
  .section-Numbers .inner-wrapper .headline {
    font-size: 1.8em;
    letter-spacing: 3px;
  }
}
.section-Numbers .inner-wrapper .numbers {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}
@media all and (min-width: 1023px) {
  .section-Numbers .inner-wrapper .numbers {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 60px;
  }
}
.section-Numbers .inner-wrapper .numbers .number-item {
  max-width: 280px;
  margin-top: 50px;
}
.section-Numbers .inner-wrapper .numbers .number-item:first-child {
  margin-top: 10px;
}
@media all and (min-width: 1023px) {
  .section-Numbers .inner-wrapper .numbers .number-item {
    margin-top: 0;
  }
  .section-Numbers .inner-wrapper .numbers .number-item:first-child {
    margin-top: 0;
  }
}
.section-Numbers .inner-wrapper .numbers .number-item .number {
  font-family: "HarmonySans", sans-serif;
  font-size: 10em;
  font-weight: 300;
  line-height: 1em;
  letter-spacing: normal;
}
.section-Numbers .inner-wrapper .numbers .number-item .text {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
  margin-top: 15px;
}
@media all and (min-width: 1023px) {
  .section-Numbers .inner-wrapper .numbers .number-item .text {
    font-size: 1.8em;
    line-height: 1.39em;
  }
}
.section-Numbers .inner-wrapper .numbers .number-item .subtext {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  line-height: 2.08em;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #00a09b;
}
@media all and (min-width: 1023px) {
  .section-Numbers .inner-wrapper .numbers .number-item .subtext {
    font-size: 1.4em;
    line-height: 1.79em;
    letter-spacing: 3px;
  }
}
@media all and (min-width: 1023px) {
  .section-Numbers .inner-wrapper .numbers .number-item .subtext {
    margin-top: 12px;
  }
}

.section-Quote .inner-wrapper .text-wrapper {
  max-width: 960px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}
.section-Quote .inner-wrapper .text-wrapper .text {
  font-family: "HarmonySans", sans-serif;
  font-size: 2.5em;
  font-weight: 400;
  line-height: 1.2em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .section-Quote .inner-wrapper .text-wrapper .text {
    font-size: 3em;
    line-height: 1.33em;
  }
}
.section-Quote .inner-wrapper .text-wrapper .text br {
  display: none;
}
@media all and (min-width: 1023px) {
  .section-Quote .inner-wrapper .text-wrapper .text br {
    display: block;
  }
}
.section-Quote .inner-wrapper .text-wrapper .subtext_1 {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
  margin-top: 12px;
}
@media all and (min-width: 1023px) {
  .section-Quote .inner-wrapper .text-wrapper .subtext_1 {
    font-size: 1.8em;
    line-height: 1.39em;
  }
}
@media all and (min-width: 1023px) {
  .section-Quote .inner-wrapper .text-wrapper .subtext_1 {
    margin-top: 25px;
  }
}
.section-Quote .inner-wrapper .text-wrapper .subtext_2 {
  font-family: "HarmonySans", sans-serif;
  font-size: 1em;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 12px;
}
@media all and (min-width: 1023px) {
  .section-Quote .inner-wrapper .text-wrapper .subtext_2 {
    font-size: 1.2em;
  }
}
@media all and (min-width: 1023px) {
  .section-Quote .inner-wrapper .text-wrapper .subtext_2 {
    margin-top: 10px;
  }
}
.section-Quote.borders {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 60px;
  margin-bottom: 60px;
}
@media all and (min-width: 1023px) {
  .section-Quote.borders {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}
.section-Quote.borders .inner-wrapper {
  border-top: 2px solid #00a09b;
  border-bottom: 2px solid #00a09b;
  padding-top: 27px;
  padding-bottom: 27px;
}
@media all and (min-width: 1023px) {
  .section-Quote.borders .inner-wrapper {
    padding-top: 66px;
    padding-bottom: 66px;
  }
}

.section-Ranking .inner-wrapper {
  display: flex;
  flex-direction: column;
}
@media all and (min-width: 1023px) {
  .section-Ranking .inner-wrapper {
    display: block;
  }
}
.section-Ranking .inner-wrapper .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 3em;
  font-weight: 400;
  line-height: 1.33em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .section-Ranking .inner-wrapper .headline {
    font-size: 4.5em;
    line-height: 1.11em;
  }
}
@media all and (min-width: 1023px) {
  .section-Ranking .inner-wrapper .headline {
    width: 45%;
    float: left;
  }
}
.section-Ranking .inner-wrapper .column-left {
  order: 2;
}
@media all and (min-width: 1023px) {
  .section-Ranking .inner-wrapper .column-left {
    width: 45%;
    float: left;
  }
}
.section-Ranking .inner-wrapper .column-right {
  order: 3;
}
@media all and (min-width: 1023px) {
  .section-Ranking .inner-wrapper .column-right {
    width: 45%;
    float: right;
    clear: right;
  }
}
@media all and (min-width: 1023px) {
  .section-Ranking .inner-wrapper .column-right .ranking-item:first-child {
    margin-top: 0;
  }
}
.section-Ranking .inner-wrapper .ranking-item {
  margin-top: 50px;
  max-width: 500px;
}
@media all and (min-width: 1023px) {
  .section-Ranking .inner-wrapper .ranking-item {
    margin-top: 89px;
  }
}
.section-Ranking .inner-wrapper .ranking-item .title {
  font-family: "HarmonySans", sans-serif;
  font-size: 2.5em;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: normal;
}
.section-Ranking .inner-wrapper .ranking-item .subtext {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.43em;
  letter-spacing: normal;
  line-height: 1.79em;
  color: rgba(76, 84, 62, 0.5);
  margin-top: 9px;
}
.section-Ranking .inner-wrapper .ranking-item .text {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.43em;
  letter-spacing: normal;
  line-height: 1.79em;
  margin-top: 15px;
}
.section-TeaserBgImage {
  padding-top: 43px;
  padding-bottom: 270px;
  background-size: cover;
}
@media all and (min-width: 1023px) {
  .section-TeaserBgImage {
    padding-top: 81px;
    padding-bottom: 140px;
  }
}
.section-TeaserBgImage .inner-wrapper .text-wrapper {
  color: #ffffff;
}
@media all and (min-width: 1023px) {
  .section-TeaserBgImage .inner-wrapper .text-wrapper {
    max-width: 500px;
  }
}
.section-TeaserBgImage .inner-wrapper .text-wrapper .supheadline {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.67em;
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 2em;
}
@media all and (min-width: 1023px) {
  .section-TeaserBgImage .inner-wrapper .text-wrapper .supheadline {
    font-size: 1.8em;
    letter-spacing: 3px;
  }
}
@media all and (min-width: 1023px) {
  .section-TeaserBgImage .inner-wrapper .text-wrapper .supheadline {
    line-height: 1.39em;
  }
}
.section-TeaserBgImage .inner-wrapper .text-wrapper .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 4.5em;
  font-weight: 300;
  line-height: 1.11em;
  letter-spacing: normal;
  margin-top: 12px;
}
@media all and (min-width: 1023px) {
  .section-TeaserBgImage .inner-wrapper .text-wrapper .headline {
    font-size: 8em;
    line-height: 0.88em;
  }
}
@media all and (min-width: 1023px) {
  .section-TeaserBgImage .inner-wrapper .text-wrapper .headline {
    margin-top: 62px;
    line-height: 1em;
  }
}
.section-TeaserBgImage .inner-wrapper .text-wrapper button {
  margin-top: 20px;
}
@media all and (min-width: 1023px) {
  .section-TeaserBgImage .inner-wrapper .text-wrapper button {
    margin-top: 42px;
  }
}

.section-TeaserBgImageFour {
  padding-top: 238px;
  padding-bottom: 248px;
  display: none;
}
@media all and (min-width: 1023px) {
  .section-TeaserBgImageFour {
    display: flex;
  }
}
.section-TeaserBgImageFour .inner-wrapper .text-wrapper {
  max-width: 450px;
  color: #ffffff;
}
.section-TeaserBgImageFour .inner-wrapper .text-wrapper .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 4.5em;
  font-weight: 400;
  line-height: 1.11em;
  letter-spacing: normal;
}
.section-TeaserBgImageFour .inner-wrapper .text-wrapper .text {
  font-family: "HarmonySans", sans-serif;
  font-size: 2em;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: normal;
  margin-top: 18px;
}
.section-TeaserBgImageFour .inner-wrapper .text-wrapper button {
  margin-top: 32px;
}

.section-TeaserBgImageThree {
  padding-top: 119px;
  padding-bottom: 95px;
}
@media all and (min-width: 1023px) {
  .section-TeaserBgImageThree {
    padding-top: 132px;
    padding-bottom: 130px;
  }
}
.section-TeaserBgImageThree .inner-wrapper .text-wrapper {
  color: #ffffff;
  max-width: 460px;
}
@media all and (min-width: 1023px) {
  .section-TeaserBgImageThree .inner-wrapper .text-wrapper {
    margin-left: auto;
  }
}
.section-TeaserBgImageThree .inner-wrapper .text-wrapper .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 3em;
  font-weight: 400;
  line-height: 1.33em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .section-TeaserBgImageThree .inner-wrapper .text-wrapper .headline {
    font-size: 4.5em;
    line-height: 1.11em;
  }
}
.section-TeaserBgImageThree .inner-wrapper .text-wrapper .text {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.43em;
  letter-spacing: normal;
  margin-top: 20px;
}
@media all and (min-width: 1023px) {
  .section-TeaserBgImageThree .inner-wrapper .text-wrapper .text {
    font-size: 2em;
    line-height: 1.5em;
  }
}
.section-TeaserBgImageThree .inner-wrapper .text-wrapper button {
  margin-top: 25px;
}
.section-TeaserBgImageThree-large {
  padding-top: 200px;
  padding-bottom: 30px;
}
@media all and (min-width: 1023px) {
  .section-TeaserBgImageThree-large {
    padding-top: 132px;
    padding-bottom: 130px;
  }
}
.section-TeaserBgImageThree-large .inner-wrapper .text-wrapper {
  color: #4c543e;
}
.section-TeaserBgImageThree-large .inner-wrapper .text-wrapper .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 3em;
  font-weight: 400;
  line-height: 1.33em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .section-TeaserBgImageThree-large .inner-wrapper .text-wrapper .headline {
    font-size: 6em;
    line-height: 1em;
  }
}

.section-TeaserBgImageTwo {
  padding-top: 36px;
  padding-bottom: 350px;
}
@media all and (min-width: 1023px) {
  .section-TeaserBgImageTwo {
    padding-top: 198px;
    padding-bottom: 194px;
  }
}
@media all and (min-width: 1023px) {
  .section-TeaserBgImageTwo .inner-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.section-TeaserBgImageTwo .inner-wrapper .image-wrapper {
  width: 140px;
}
@media all and (min-width: 1023px) {
  .section-TeaserBgImageTwo .inner-wrapper .image-wrapper {
    width: 250px;
  }
}
.section-TeaserBgImageTwo .inner-wrapper .text-wrapper {
  color: #ffffff;
}
@media all and (min-width: 1023px) {
  .section-TeaserBgImageTwo .inner-wrapper .text-wrapper {
    max-width: 360px;
  }
}
.section-TeaserBgImageTwo .inner-wrapper .text-wrapper .headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 2.5em;
  font-weight: 400;
  line-height: 1.2em;
  letter-spacing: normal;
  margin-top: 24px;
}
@media all and (min-width: 1023px) {
  .section-TeaserBgImageTwo .inner-wrapper .text-wrapper .headline {
    font-size: 4.5em;
    line-height: 1.11em;
    margin-top: 0;
  }
}
.section-TeaserBgImageTwo .inner-wrapper .text-wrapper .text {
  display: none;
  font-family: "HarmonySans", sans-serif;
  font-size: 1.8em;
  font-weight: 400;
  line-height: 1.39em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .section-TeaserBgImageTwo .inner-wrapper .text-wrapper .text {
    display: block;
    margin-top: 10px;
  }
}
.section-TeaserBgImageTwo .inner-wrapper .text-wrapper button {
  margin-top: 26px;
}

.section-Text {
  padding-bottom: 53px;
}
.section-Text .inner-wrapper .text {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.56em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .section-Text .inner-wrapper .text {
    font-size: 2em;
    line-height: 1.5em;
  }
}
.section-Text .inner-wrapper .text p {
  padding: 0.4em 0 0 0;
}
@media all and (min-width: 1023px) {
  .section-Text .inner-wrapper .text p {
    padding: 0.8em 0 0 0;
  }
}
.section-Text .inner-wrapper .text p strong {
  display: block;
  margin-top: 1em;
}
@media all and (min-width: 1023px) {
  .section-Text .inner-wrapper .text p strong {
    margin-top: 2em;
  }
}
.section-Text.is-pale {
  padding-bottom: 20px;
}
@media all and (min-width: 1023px) {
  .section-Text.is-pale {
    padding-bottom: 100px;
  }
}
.section-Text.is-pale .lines {
  display: none;
}

.section-TextLarge {
  padding-bottom: 28px;
}
@media all and (min-width: 1023px) {
  .section-TextLarge {
    padding-bottom: 60px;
  }
}
.section-TextLarge .inner-wrapper .text {
  font-family: "HarmonySans", sans-serif;
  font-size: 2em;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: normal;
}
@media all and (min-width: 1023px) {
  .section-TextLarge .inner-wrapper .text {
    font-size: 3em;
    line-height: 1.33em;
  }
}

.section-VideoImg .inner-wrapper .video-wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 56%;
  position: relative;
}
.section-VideoImg .inner-wrapper .video-wrapper iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.section-VideoImg .inner-wrapper .video-wrapper iframe .ytp-chrome-top {
  display: none;
}
.section-VideoImg .inner-wrapper .image-wrapper {
  position: absolute;
  width: 100%;
  height: 0;
  padding-bottom: 56%;
  position: relative;
}

.site-footer.has-space {
  padding-top: 93px;
}
@media all and (min-width: 1023px) {
  .site-footer.has-space {
    padding-top: 114px;
  }
}
.site-footer.lines-before {
  z-index: 1;
}
.site-footer.lines-before:before, .site-footer.lines-before:after {
  content: "";
  width: 2px;
  position: absolute;
  background-color: #00a09b;
  height: 260px;
  top: -260px;
}
.site-footer.lines-before:before {
  margin-left: 4%;
  left: 0;
}
.site-footer.lines-before:after {
  margin-right: 4%;
  right: 0;
}
.site-footer .inner-wrapper {
  background-color: #e7e7e6;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 4.5em;
}
@media all and (min-width: 1023px) {
  .site-footer .inner-wrapper {
    padding-bottom: 7em;
  }
}
.site-footer .inner-wrapper .footer-head {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
@media all and (min-width: 1023px) {
  .site-footer .inner-wrapper .footer-head {
    flex-direction: row;
    justify-content: flex-start;
  }
}
@media all and (min-width: 1023px) {
  .site-footer .inner-wrapper .footer-head .logo-link {
    flex: 0 1 20%;
  }
}
.site-footer .inner-wrapper .footer-head .svg-icn-signet {
  width: 41px;
  height: 41px;
}
@media all and (min-width: 1023px) {
  .site-footer .inner-wrapper .footer-head .svg-icn-signet {
    width: 81px;
    height: 84px;
  }
}
.site-footer .inner-wrapper .footer-head .social {
  display: flex;
  margin-top: 21px;
  order: 3;
}
@media all and (min-width: 1023px) {
  .site-footer .inner-wrapper .footer-head .social {
    flex-direction: column;
    order: 2;
    margin-top: 0;
  }
}
.site-footer .inner-wrapper .footer-head .social .sl {
  padding: 10px;
}
@media all and (min-width: 1023px) {
  .site-footer .inner-wrapper .footer-head .social .sl {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
.site-footer .inner-wrapper .footer-head .social .sl .svg {
  margin-top: -4px;
}
.site-footer .inner-wrapper .footer-head .social .sl .sn {
  font-family: "HarmonySans", sans-serif;
  font-weight: 400;
  color: #575757;
  font-size: 1.8em;
  line-height: 1em;
  display: none;
}
@media all and (min-width: 1023px) {
  .site-footer .inner-wrapper .footer-head .social .sl .sn {
    display: block;
    margin-left: 9px;
  }
}
.site-footer .inner-wrapper .footer-head .nl {
  width: 100%;
  order: 2;
  margin-top: 44px;
  max-width: 460px;
}
@media all and (min-width: 1023px) {
  .site-footer .inner-wrapper .footer-head .nl {
    order: 3;
    margin-left: auto;
    margin-top: 0;
  }
}
.site-footer .inner-wrapper .footer-head .nl .nl-headline {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.25em;
  letter-spacing: normal;
  color: #00a09b;
  text-align: center;
}
@media all and (min-width: 1023px) {
  .site-footer .inner-wrapper .footer-head .nl .nl-headline {
    font-size: 1.8em;
    line-height: 1.39em;
  }
}
@media all and (min-width: 1023px) {
  .site-footer .inner-wrapper .footer-head .nl .nl-headline {
    text-align: left;
  }
}
.site-footer .inner-wrapper .footer-head .nl .nl-anmeldung {
  margin-top: 16px;
  position: relative;
}
@media all and (min-width: 1023px) {
  .site-footer .inner-wrapper .footer-head .nl .nl-anmeldung {
    margin-top: 24px;
  }
}
.site-footer .inner-wrapper .footer-head .nl .nl-anmeldung .input-wrapper {
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  display: flex;
  align-items: strech;
  justify-content: space-between;
}
.site-footer .inner-wrapper .footer-head .nl .nl-anmeldung .input-wrapper .input-text {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.79em;
  letter-spacing: normal;
  border: 0;
  background-color: transparent;
  flex: auto;
  padding: 0 1.9em 0 1.9em;
}
@media all and (min-width: 1023px) {
  .site-footer .inner-wrapper .footer-head .nl .nl-anmeldung .input-wrapper .input-text {
    font-size: 1.8em;
    line-height: 1.39em;
  }
}
.site-footer .inner-wrapper .footer-head .nl .nl-anmeldung .input-wrapper .input-text ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: "HarmonySans", sans-serif;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.79em;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.5);
}
@media all and (min-width: 1023px) {
  .site-footer .inner-wrapper .footer-head .nl .nl-anmeldung .input-wrapper .input-text ::-webkit-input-placeholder {
    font-size: 1.8em;
    line-height: 1.39em;
  }
}
.site-footer .inner-wrapper .footer-head .nl .nl-anmeldung .input-wrapper .input-text ::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "HarmonySans", sans-serif;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.79em;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.5);
}
@media all and (min-width: 1023px) {
  .site-footer .inner-wrapper .footer-head .nl .nl-anmeldung .input-wrapper .input-text ::-moz-placeholder {
    font-size: 1.8em;
    line-height: 1.39em;
  }
}
.site-footer .inner-wrapper .footer-head .nl .nl-anmeldung .input-wrapper .input-text :-ms-input-placeholder {
  /* IE 10+ */
  font-family: "HarmonySans", sans-serif;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.79em;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.5);
}
@media all and (min-width: 1023px) {
  .site-footer .inner-wrapper .footer-head .nl .nl-anmeldung .input-wrapper .input-text :-ms-input-placeholder {
    font-size: 1.8em;
    line-height: 1.39em;
  }
}
.site-footer .inner-wrapper .footer-head .nl .nl-anmeldung .input-wrapper .input-text :-moz-placeholder {
  /* Firefox 18- */
  font-family: "HarmonySans", sans-serif;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.79em;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.5);
}
@media all and (min-width: 1023px) {
  .site-footer .inner-wrapper .footer-head .nl .nl-anmeldung .input-wrapper .input-text :-moz-placeholder {
    font-size: 1.8em;
    line-height: 1.39em;
  }
}
.site-footer .inner-wrapper .footer-head .nl .nl-anmeldung .input-wrapper .input-submit {
  font-family: "HarmonySans", sans-serif;
  font-size: 1em;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: 2px;
  padding: 0 1.7em 0 1.7em;
  border: 0;
  background-color: transparent;
  color: #00a09b;
  text-transform: uppercase;
}
@media all and (min-width: 1023px) {
  .site-footer .inner-wrapper .footer-head .nl .nl-anmeldung .input-wrapper .input-submit {
    font-size: 1.2em;
  }
}
.site-footer .inner-wrapper .footer-head .nl .nl-anmeldung .input-wrapper .svg-circle {
  width: 20px;
  height: 20px;
  right: -28px;
  top: 15px;
}
.site-footer .inner-wrapper .footer-head .nl .nl-anmeldung .input-error {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.25em;
  letter-spacing: normal;
  color: #00a09b;
  margin-top: 0.5em;
  position: absolute;
  bottom: -30px;
}
@media all and (min-width: 1023px) {
  .site-footer .inner-wrapper .footer-head .nl .nl-anmeldung .input-error {
    font-size: 1.6em;
    line-height: 1.39em;
  }
}
.site-footer .inner-wrapper .footer-bottom {
  margin-top: 5px;
}
@media all and (min-width: 1023px) {
  .site-footer .inner-wrapper .footer-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 75px;
  }
}
@media all and (min-width: 1023px) {
  .site-footer .inner-wrapper .footer-bottom .menu-footer-menue-en-container,
.site-footer .inner-wrapper .footer-bottom .menu-footer-menue-de-container {
    order: 2;
  }
}
.site-footer .inner-wrapper .footer-bottom .menu {
  display: flex;
  justify-content: center;
}
.site-footer .inner-wrapper .footer-bottom .menu .menu-item {
  font-family: "HarmonySans", sans-serif;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.43em;
  letter-spacing: normal;
}
.site-footer .inner-wrapper .footer-bottom .menu .menu-item a {
  padding: 10px;
}
.site-footer .inner-wrapper .footer-bottom .footer-bottom-text {
  text-align: center;
  margin-top: 10px;
  font-family: "HarmonySans", sans-serif;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.43em;
  letter-spacing: normal;
  color: rgba(87, 87, 87, 0.5);
}
@media all and (min-width: 1023px) {
  .site-footer .inner-wrapper .footer-bottom .footer-bottom-text {
    flex: 0 1 20%;
    order: 1;
    margin-top: 0;
    text-align: left;
  }
}

.pum.pum-overlay .pum-title, .pum-theme-enterprise-blue .pum-title {
  font-weight: 400 !important;
  font-size: 2.6em !important;
  line-height: 1.11em !important;
  margin-bottom: 21px !important;
  font-family: "HarmonySans", sans-serif !important;
}

.pum.pum-overlay .pum-content, .pum-theme-enterprise-blue .pum-content {
  color: #4c543e !important;
  font-family: "HarmonySans", sans-serif !important;
  font-size: 1em !important;
  font-weight: 400 !important;
  line-height: 1.56em !important;
  letter-spacing: normal !important;
  margin-bottom: 0 !important;
}

.pum-container .pum-content > :last-child {
  margin-top: 20px;
}

.pum.pum-overlay .pum-content + .pum-close, .pum-theme-enterprise-blue .pum-content + .pum-close {
  font-family: "HarmonySans", sans-serif !important;
}

/*# sourceMappingURL=Style.css.map */
